import React, { useEffect, useState } from 'react'
import GenericFormHeaderComponent from '../../../genericComponents/forms/GenericFormHeaderComponent'
import { convertCamelCase } from '../../../helper/genericFunction';
import { Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchHandler, handleApiChange } from '../../../helper/apiHelperFunctions';
import GenericSpinnerLoader from '../../../loaders/GenericSpinnerLoader';
import { parseJwt } from '../../../helper/genericFunction';
import NoDataFound from '../../../genericComponents/NoDataFound';
import Icons from '../../../assets/icons';
import GenericCustomButton from '../../../genericComponents/GenericCustomButton';
import ImageUpload from '../create/product/ImageUpload';
import GenericViewDetailsComponent from '../../../genericComponents/viewDetails/GenericViewDetailsComponent';
import LanguageTranslator from '../../../locals/LanguageTranslator';

const ViewProductDetails = (props) => {
    const uType = parseJwt(sessionStorage.getItem('token')).uType?.toUpperCase() || "ENT";
    const {
        closeModal,
        viewPageType = "product",
        selectedData = {},
    } = props;

    const displayKey = `isDisplay${uType == "ENT" ? "Retailer" : "Vendor"}`

    const dispatch = useDispatch();
    const getProductDetailData = useSelector(state => state?.digicatStore?.getProductDetailData);
    const loadFabricData = useSelector(state => state?.digicatStore?.loadFabricData);
    const loadCostsheetData = useSelector(state => state?.digicatStore?.loadCostsheetData);
    const getDigicatFormSettingsData = useSelector(state => state?.administrationStore?.getDigicatFormSettingsData);
    const getProductDetailCustomData = useSelector(state => state?.digicatStore?.getProductDetailCustomData)
    const generalStore = useSelector((state) => state.generalStore);

    // State : To store the current tab
    const [currentTab, setCurrentTab] = useState(viewPageType);
    const [tableData, setTableData] = useState(null);
    const [formSettings, setFormSettings] = useState({});

    // State: To store the current expanded fabric data
    const [expandedFabric, setExpandedFabric] = useState(null);
    const [fabricFocusedRow, setFabricFocusedRow] = useState(null);

    // State : To store the images data
    const [imagesData, setImagesData] = useState({});
    const [responseData, setResponseData] = useState(null);

    // Custom Attributes Tab Headers - 
    const [headersData, setHeadersData] = useState({});
    const [headersType, setHeadersType] = useState({});

    // handle : the repsonse of the product details api for the product tab
    useEffect(() => {
        if (getProductDetailData?.isSuccess) {
            setTableData(getProductDetailData?.data?.resource?.data || {});
            setImagesData(updateMediaData(getProductDetailData?.data?.resource?.data || {}))
        }
    }, [getProductDetailData?.isSuccess])

    // Handle the response of the fabric details api
    useEffect(() => {
        if (loadFabricData?.isSuccess) {
            setTableData(loadFabricData?.data?.resource || []);
            if (loadFabricData?.data?.resource?.length > 0) {
                setFabricFocusedRow(loadFabricData?.data?.resource?.[0]);
            }
        }
    }, [loadFabricData?.isSuccess])

    useEffect(() => {
        if(getProductDetailCustomData?.isSuccess) {
            setTableData(getProductDetailCustomData?.data?.resource?.data || []);
            setImagesData(updateMediaData(getProductDetailData?.data?.resource?.data || {}))
        }

        if (generalStore?.getHeadersData?.isSuccess && generalStore?.getHeadersData?.data?.basedOn == "details") {
            let headersTypedata = Object.keys(generalStore?.getHeadersData?.data?.resource?.["Custom Headers"] || {})?.length > 0 ? "Custom Headers" : "Default Headers";
            setHeadersData(generalStore?.getHeadersData?.data?.resource || {})
            setHeadersType(headersTypedata)
        }
    }, [getProductDetailCustomData?.isSuccess, generalStore?.getHeadersData?.isSuccess])

    // Handle : The response of the costsheet details api
    useEffect(() => {
        if (loadCostsheetData?.isSuccess) {
            setTableData(loadCostsheetData?.data?.resource?.[0] || []);
            setResponseData(loadCostsheetData?.data?.resource || {});
        }
    }, [loadCostsheetData?.isSuccess])

    console.log("tableData", tableData);
    useEffect(() => {
        if (getDigicatFormSettingsData?.isSuccess) {
            setFormSettings(getDigicatFormSettingsData?.data?.resource || {});
        }
    }, [getDigicatFormSettingsData?.isSuccess])

    useEffect(() => {
        getTableData();
    }, [currentTab])

    // Whenever the focused fabric row changes then update the fabric image
    useEffect(() => {
        setImagesData(updateMediaData(fabricFocusedRow))
    }, [fabricFocusedRow])

    // Function : To update the media data and set the media data inside the state
    const updateMediaData = (data, updatedMediaData = {}) => {
        switch (currentTab) {
            case "product": {
                let keys = ["imageUrlFront", "imageUrlBack", "imageUrlSide", "imageUrlDetailed", "imageUrlLook", "videoUrl", "rawImage1", "rawImage2", "pdfUrl", "teckPackUrl"];
                keys?.map(key => {
                    if (data?.[key]) {
                        updatedMediaData[key] = {
                            "url": data?.[`${key}Url`] || "",
                            "filePath": data?.[key]
                        }
                    }
                })
                break;
            }
            case "fabric": {
                ["fabricImage"]?.map(key => {
                    if (data?.[key]) {
                        updatedMediaData[key] = {
                            "url": data?.[`${key}Url`] || "",
                            "filePath": data?.[key]
                        }
                    }
                })
            }
        }

        return updatedMediaData;
    }

    // Funcion : To render the header componernt
    const renderHeaderComponent = () => {
        return <GenericFormHeaderComponent
            label={{
                "contentPrimary": `${convertCamelCase(viewPageType)} Details`,
                "contentSecondry": `View complete ${viewPageType} details for selected ${viewPageType}.`
            }}
            closeModal={closeModal}
        />
    }

    const getTableData = () => {
        switch (currentTab) {
            case "product": {
                dispatchHandler(dispatch, "getProductDetailRequest", { "productId": selectedData?.productId });
                break;
            }
            case "fabric": {
                dispatchHandler(dispatch, "loadFabricRequest", {
                    "dataValue": viewPageType != "product" ? selectedData?.fabricCode || "" : selectedData?.productId || "",
                    "isGetProductFabric": viewPageType == "product" ? true : false,
                })
                break;
            }
            case "costsheet": {
                dispatchHandler(dispatch, "loadCostsheetRequest", {
                    "dataValue": viewPageType != "product" ? selectedData?.costSheetNo || "" : selectedData?.productId || "",
                    "isProductCostSheet": viewPageType == "product" ? true : false,
                })
                break;
            }
            case "customAttributes": {
                dispatchHandler(dispatch, "getProductDetailCustomRequest", {
                    "id": selectedData?.id || "",
                })
                handleApiChange(dispatch, 'getHeadersRequest', { displayName: "PRODUCT_LIBRARY_DETAIL_CHINA", basedOn: "details" })
            }
        }
        let payloadKey = {
            "product": "productCatalogue",
            "fabric": "fabric",
            "costsheet": "costSheet",
        }
        if(currentTab !== "customAttributes") {
            dispatchHandler(dispatch, "getDigicatFormSettingsRequest", {
                type: payloadKey?.[currentTab] || ""
            })
        }
    }

    // Function : To render the details of the product;
    const renderDetails = () => {

        let isLoading = {
            "product": getProductDetailData?.isLoading,
            "fabric": loadFabricData?.isLoading,
            "costsheet": loadCostsheetData?.isLoading,
            "customAttributes": getProductDetailCustomData?.isLoading,
        }

        if (isLoading?.[currentTab] || getDigicatFormSettingsData?.isLoading) {
            return <GenericSpinnerLoader />
        }

        switch (currentTab) {
            case "product": {
                return renderProductDetails();
            }
            case "fabric": {
                return renderFabricDetails();
            }
            case "costsheet": {
                return renderCostsheetDetails()
            }
            case "customAttributes": {
                return renderCustomAttributes()
            }
        }
    }

    const isDisplayKey = (key) => {
        console.log("jey", formSettings, key);
        return formSettings?.[key]?.[displayKey] == 1;
    }

    // State : To check if the keys is amount
    const isAmount = (key) => {
        if ((responseData?.[0]?.tradeDiscountFormat?.toLowerCase() == "rupees" ? ["totalSupplierCost", "tradeDiscount"] : ["totalSupplierCost"])?.includes(key)) {
            return true;
        }
        return false;
    }

    const isPercentage = (key) => {
        if((responseData?.[0]?.tradeDiscountFormat?.toLowerCase() == "rupees" ? []  : ["tradeDiscount"])?.includes(key)){
            return true;
        }
        return false;
    }

    // Function : To provide the current total supplier cost
    const getCurrentTotalSupplierCost = (type) => {
        let totalCost = 0;
        let unitCostKey = uType == "ENT" ? "retailerUnitCost" : "vendorUnitCost";
        tableData?.materialDets?.map(item => {
            totalCost += (Number(item?.[unitCostKey] || "") * Number(item?.["qty"] || ""));
        })
        return totalCost?.toFixed(2);
    }

    const getCurrentRowCostPercentage = (item) => {
        let unitCostKey = uType == "ENT" ? "retailerUnitCost" : "vendorUnitCost";
        return (Number((Number(item?.[unitCostKey] || "") * Number(item?.["qty"] || "")) / Number(getCurrentTotalSupplierCost("original") || "") || "") * 100)?.toFixed(2);
    }

    // Function : To render the cost sheet details
    const renderCostsheetDetails = () => {
        let keys = ["bomType", "materialName", "type", "subType", "qty", "vendorUnitCost", "retailerUnitCost", "proposedCost", "percentage"];
        return <div className='dfvd-costsheet'>

            {/* Render the top level cost sheet details */}
            <div className='dfvdc-det'>
                {["costSheetName", "totalSupplierCost", "tradeDiscount"]?.map(key => {
                    if (isDisplayKey(key)) {
                        return <div className='dfvdcd'>
                            <label>{formSettings?.[key]?.["displayName"]}</label>
                            <p>{isAmount(key) && <span>&#8377;</span>} {tableData?.[key] || "-"} {isPercentage(key) && <span> % </span>}</p>
                        </div>
                    }
                })}
            </div>

            {/* Render the actual table for the costsheet */}
            <div className='dfvdc-table'>
                <table>
                    <thead>
                        <tr>
                            {keys?.map((key, index) => (
                                isDisplayKey(key) && <th key={`header-${index}`}>{formSettings?.[key]?.displayName}</th>
                            ))}
                        </tr>
                    </thead>
                    {tableData?.length == 0 && <tbody className='!border-0'>
                        <tr>
                            <td colSpan='100' className='!border-0'>
                                <p className='td-no-data'>No Data Found !</p>
                            </td>
                        </tr>
                    </tbody>}
                    <tbody>
                        {tableData?.materialDets?.map((item, index) => (
                            <tr key={`row-${index}`}>
                                {keys?.map((key, subIndex) => (
                                    isDisplayKey(key) && (
                                        <td key={`head-${subIndex}`}>
                                            <label>{key == "percentage" ?
                                                getCurrentRowCostPercentage(item)
                                                : item?.[key]}
                                            </label>
                                        </td>
                                    )
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    }

    // Function : To render the custom cols 
    const renderCustomColumns = (key) => {
        {
            switch (key) {
                case "fabricComposition": {
                    return <div className='gvmb w-full'>
                        <div className='font-semibold'>{formSettings?.[key]?.displayName || ""}</div>

                        {fabricFocusedRow?.[key]?.split('%')?.length == 0 ? <p style={{ marginRight: "10px" }}>-</p> : fabricFocusedRow?.[key]?.split('%')?.map(item => (
                            item && <p style={{ marginRight: "10px" }}>{item} %</p>
                        ))}
                    </div>
                }
                case "color" :
                case "fabricColor": {
                    return <div className='gvmb'>
                        <label className='mb-0'>{formSettings?.[key]?.displayName || ""}</label>
                        <p>{fabricFocusedRow?.["fabricColor"]?.name}</p>
                    </div>
                }
                case "constructionJson": {
                    return <div className='gvmb'>
                        <label className='mb-0'>{formSettings?.[key]?.displayName || ""}</label>
                        <p>{fabricFocusedRow?.[key]?.[0] ? fabricFocusedRow?.[key]?.[0] : ""} {fabricFocusedRow?.[key]?.[1] ? " X " + fabricFocusedRow?.[key]?.[1] : ""}</p>
                    </div>
                }
                case "fabricDyeYarnCount":
                case "yarnCount": {
                    return <div className='gvmb w-full'>
                        <label>{formSettings?.[key]?.displayName || ""}</label>
                        {typeof(fabricFocusedRow?.[key]) == "string" ? <p>{fabricFocusedRow?.[key]}</p> : <p>
                            {fabricFocusedRow?.[key]?.[0] ? fabricFocusedRow?.[key]?.[0] : ""}
                            {fabricFocusedRow?.[key]?.[1] ? " + " + fabricFocusedRow?.[key]?.[1] : ""}
                            {fabricFocusedRow?.[key]?.[2] ? " X " + fabricFocusedRow?.[key]?.[2] : ""}
                            {fabricFocusedRow?.[key]?.[3] ? " + " + fabricFocusedRow?.[key]?.[3] : ""}
                        </p>}
                    </div>
                }
                case "collection":
                case "color":
                case "size":
                    return <div className='gvmb' >
                        <label className='mb-0'>{formSettings?.[key]?.displayName || ""}</label>
                        <div className='flex flex-wrap'>
                            {tableData?.[key]?.length == 0 ? <p>-</p> : tableData?.[key]?.map(item => (
                                <p className='p-bg-grey'>{item?.name || ""}</p>
                            ))}
                        </div>
                    </div>
            }
        }

    }

    // Function : To provide the view configuration
    const prepareViewConfiguration = (keys) => {
        let obj = {};
        // The fabric keys to render the data;
        keys?.map(key => {
            if (isDisplayKey(key)) {
                obj[key] = ["fabricDyeYarnCount", "constructionJson", "fabricColor", "yarnCount", "fabricComposition", "color", "size", "collection"]?.includes(key) ? () => renderCustomColumns(key) : formSettings?.[key]?.displayName || "";
            }
        });
        return obj;
    }

    // Function : To render the fabric details 
    const renderFabricDetails = () => {
        if (tableData?.length == 0) {
            return <div className='dfvb-det'>
                <NoDataFound primaryText="No Fabrics Found!" />
            </div>
        }

        let fabricKeys = prepareViewConfiguration(Object.keys(formSettings || {}));

        return (
            <div className='dfvb-det'>
                <div className='dfvbd-fabric'>
                    {tableData?.map(item => (
                        <div className='dfvbdf-det' onClick={() => setFabricFocusedRow(item)}>
                            <div className='frd-top'>
                                <div className='flex items-center'>
                                    {item?.fabricImageUrl ?
                                        <div className='frdt-img'>
                                            <img src={item?.fabricImageUrl} />
                                        </div> :
                                        <div className='frdt-img'>
                                            <Icons.ImageThumbnail />
                                        </div>
                                    }
                                    <div className='p-lft-10'>
                                        <label>Fabric Code</label>
                                        <p>{item?.fabricCode || ""}</p>
                                    </div>
                                </div>
                                {/* Render the expand collapse toggle button */}
                                <GenericCustomButton
                                    label={expandedFabric?.id === item?.id ? "Collapse Details" : "Expand For Details"}
                                    rightIcon={<Icons.DownArrowNew className={expandedFabric?.id === item?.id && "rotate-180"} />}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (item?.id === expandedFabric?.id) {
                                            setExpandedFabric(null);
                                        } else {
                                            setExpandedFabric(item);
                                            setFabricFocusedRow(item);
                                        }
                                    }}
                                />
                            </div>
                            <div className='frd-bot'>
                                <p>{item?.fabricName}</p>
                            </div>
                            {/* Place to render the expanded details for the fabric row */}
                            {expandedFabric?.id === item?.id && (
                                <div className='dfvbdf-data'>
                                    <GenericViewDetailsComponent
                                        label=""
                                        sections={{
                                            basicDetails: "",
                                        }}
                                        headerKeys={{
                                            "basicDetails": {
                                                "numOfCols": 1,
                                                "keys": fabricKeys,
                                            }
                                        }}
                                        selectedData={fabricFocusedRow}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {/* Render the image section here */}
                <div className='dfvbd-right'>
                    {renderImageSection()}
                </div>
            </div>
        )
    }

    // Function : To render the product details
    const renderProductDetails = () => {
        let basicKeys = ["hl1Name", "hl2Name", "hl3Name", "designGroupNumber", "season", "itemName", "color", "size", "description", "costPrice"];
        let basicDetailsKeys = prepareViewConfiguration(basicKeys);
        let attributeKeys = Object.keys(formSettings || {})?.filter(key => !basicKeys?.includes(key));
        let attributeDetailsKeys = prepareViewConfiguration(attributeKeys);


        return <div className='dfvb-det'>
            <div className='dfvbd-left'>
                <GenericViewDetailsComponent
                    label=""
                    sections={{
                        basicDetails: "BASIC DETAILS",
                        attributes: "ATTRIBUTES"
                    }}
                    headerKeys={{
                        "basicDetails": {
                            "numOfCols": 1,
                            "keys": basicDetailsKeys,
                        },
                        "attributes": {
                            "numOfCols": 1,
                            "keys": attributeDetailsKeys,
                        }
                    }}
                    selectedData={tableData}
                />
            </div>
            <div className='dfvbd-right'>
                {renderImageSection()}
            </div>
        </div>
    }

    // Function : To render the custom attributes tab 
    const renderCustomAttributes = () => {
        return <div className='dfvb-det'>
            <div className='dfvbd-left'>
                <div className='generic-view-modal'>
                    <div className='gvm-scroll'>
                        <div className='gvm-body'>
                            {Object.keys(headersData || {}).length > 0 && Object.keys(headersData?.[headersType] || {})?.map(key => (
                                <div className='gvmb' key={key}>
                                    <label>{headersData[headersType][key]}</label>
                                    <p>{tableData?.[0]?.[key] || "0"}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='dfvbd-right'>
                {renderImageSection()}
            </div>
        </div>
    }

    // Function : To render the image section for the product and fabric
    const renderImageSection = () => {
        return <ImageUpload
            module={currentTab == "customAttributes" ? "product" : currentTab}
            viewOnly={true}
            setMediaData={setImagesData}
            mediaData={imagesData}

            formSettings={formSettings}
            displayKey={displayKey}
        />
    }

    // Function : To reset all the current states
    const resetStates = () => {
        setTableData(null);
        setFormSettings({});
        setImagesData(null);
        setFabricFocusedRow(null);
        setExpandedFabric(null);
    }

    const changeTab = (val) => {
        resetStates();
        setCurrentTab(val);
    }

    let parseToken = parseJwt(sessionStorage.getItem('token')), deleteRowOnTheseIds = ["8534", "754"];

    // Function : To render the tabs 
    const renderTabsSection = () => {
        return (
            <div className='dfvb-radio'>
                <Radio.Group value={currentTab} onChange={(e) => changeTab(e?.target?.value)}>
                    <Radio.Button value={"product"}><LanguageTranslator>Product Details</LanguageTranslator></Radio.Button>
                    <Radio.Button value={"fabric"}><LanguageTranslator>Fabric</LanguageTranslator></Radio.Button>
                    <Radio.Button value={"costsheet"}><LanguageTranslator>Costsheet</LanguageTranslator></Radio.Button>
                    {deleteRowOnTheseIds?.includes(parseToken?.schemaEntID) && <Radio.Button value={"customAttributes"}><LanguageTranslator>Custom Attributes</LanguageTranslator></Radio.Button>}
                </Radio.Group>
            </div>
        )
    }

    return (
        <div className='df-view-img viewProductDetails'>
            {renderHeaderComponent()}

            {/* Render the content of view details in two portion */}
            <div className='dfv-body'>
                {/* Render the details section */}
                <div className='h-100'>
                    {/* Render tabs */}
                    {viewPageType == "product" && renderTabsSection()}

                    {renderDetails()}
                </div>
                {/* render the image section */}
                {["product", "fabric"]?.includes(currentTab) && <div>

                </div>}
            </div>
        </div>
    )
}

export default ViewProductDetails