import React, { useState, useEffect } from 'react'
import Icons from '../../../../assets/icons';
import { parseJwt } from '../../../../helper/genericFunction';
import ProfileDetails from './ProfileDetails';
import { dispatchHandler } from '../../../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import ResetPassword from '../../../../authComponents/login/forgot/ResetPassword';
import DataMappingView from './DataMappingView';
import Settings from './settings/Settings';
import LanguageTranslator from '../../../../locals/LanguageTranslator';
import { sendButtonClickEventToGoogleAnalytics } from '../../../../helper/helperTableFunctions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Waypoints } from 'lucide-react';
// import SupportComponent from './SupportComponent';

const UserProfileHome = () => {
    const tokenData = parseJwt(sessionStorage?.getItem("token")) || {};
    const dispatch = useDispatch();
    const authStore = useSelector(state => state.authStore)
    const location = useLocation();

    const [currentTab, setCurrentTab] = useState("User Info");

    useEffect(() => {
        if(location?.state?.currentTab) {
            setCurrentTab(location?.state?.currentTab);
        }
    }, []);

    // const [isSupportUser, setIsSupportUser] = useState(false);

    // useEffect(() => {

    //     if(authStore?.["getSupportUserData"]?.["data"]?.["resource"]){
    //         setIsSupportUser(authStore?.["getSupportUserData"]?.["data"]?.["resource"]?.["is_user"] == 1);
    //     }else{
    //         dispatchHandler(dispatch, "getSupportUserRequest", {
    //             username : tokenData?.prn || ""
    //         })
    //     }
    // }, [])


    // useEffect(()=>{
    //     if(authStore?.["getSupportUserData"]?.isSuccess){
    //         setIsSupportUser(authStore?.["getSupportUserData"]?.["data"]?.["resource"]?.["is_user"] == 1);
    //     }
    // },[authStore?.["getSupportUserData"]?.isSuccess])


    const renderSideTabs = () => {
        // REnder the profile tabs data
        return [
            { label: "User Info", icon: <Icons.UserIcon /> },
            { label: "Change Password", icon: <Icons.PasswordLockIcon /> },
            // isSupportUser && {label : "Support Access", icon : <Icons.SupportIcon/>},
            parseJwt(sessionStorage.getItem('token'))?.uType === "ENT" && { label: "Data Mapping", icon: <Waypoints size={19} strokeWidth={1.5} className='text-[#67768E] group-hover:text-[#12203C]' /> },
            {label : "Settings", icon: <Icons.ProfileSettingIcon />}
        ]?.map(item => (<div className={`upl-det ${currentTab == item?.label && "upl-active"} ${currentTab == item?.label && item?.label === 'Data Mapping' && 'setIconActive'} group`} onClick={() => {
            setCurrentTab(item?.label);
            sendButtonClickEventToGoogleAnalytics({
                label: item?.label, location : location,
            })
        }}>
            <LanguageTranslator tag="span">{item?.icon}</LanguageTranslator>
            <LanguageTranslator tag="p">{item?.label}</LanguageTranslator>
        </div>))

    }

    // Funcction: TO render the profile component here
    const renderProfileComponents = () => {
        switch (currentTab) {
            case "User Info": {
                return <ProfileDetails />
            }
            case "Change Password": {
                return <ResetPassword
                    changePassword={(data) => handleChange("changePassword", data)}
                    showCurrentPass={true}
                />
            }
            // case "Support Access" : {
            //     return <SupportComponent/>
            // }
            case "Data Mapping": {
                return <DataMappingView />
            }
            case "Settings" : {
                return <Settings/>
            }
        }
    }

    const handleChange = (type, data) => {
        switch (type) {
            case "changePassword": {
                dispatchHandler(dispatch, "changePasswordRequest", {
                    token: sessionStorage?.getItem("token") || "",
                    password: btoa(data?.newPass),
                    oldpassword: btoa(data?.currentPass),
                    hash: "true"
                })
                break;
            }
        }
    }

    return (
        <div className='user-profile'>
            <div className='up-left'>
                <div className='upl-head'>
                    <LanguageTranslator tag="h3">User Profile</LanguageTranslator>   
                </div>
                {renderSideTabs()}
            </div>
            <div className='up-right'>
                {renderProfileComponents()}
            </div>
        </div>
    )
}

export default UserProfileHome