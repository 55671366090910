import React, { useEffect, useState, useContext } from 'react'
import ParentModal from '../../genericComponents/ParentModal';
import { deleteCookie, parseJwt } from '../../helper/genericFunction';
import EditEmail from '../home/profileSection/userProfile/EditEmail';
import { dispatchHandler } from '../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import VendorMerge from './VendorMerge';
import { deleteDB } from 'idb';
import FullPageLoader from '../../loaders/FullPageLoader';
import { isSetAutoLoginCookie } from '../../authComponents/login/helper/autoLoginFunctions';
import { setCookie } from '../../helper/genericFunction';
import { ReloadContext } from '../../App';
// Component : The default pop up component to show the modals at the login time
const DefaultPopUp = () => {
    const { handleDocumentStatusChange } = useContext(ReloadContext);
    const dispatch = useDispatch();

    const verifyOtpData = useSelector(state => state?.authStore?.verifyOtpData);

    const [openModal, setOpenModal] = useState(false);
    const [openModalData, setOpenModalData] = useState(null);

    // State : to store the refrehs pop up or not.
    const [refreshPopup, setRefreshPopup] = useState(true);

    const tokenData = parseJwt(sessionStorage.getItem("token") || "");

    useEffect(()=>{
        if(verifyOtpData?.isSuccess){
            let token = verifyOtpData?.data?.resource?.token
            if(token){
                sessionStorage?.removeItem("token");
                localStorage?.removeItem("token");
                sessionStorage?.setItem("token", token || "");
                localStorage?.setItem("token", token || "");
                deleteCookie();
                token && isSetAutoLoginCookie() && setCookie({name : "jwt", value : token, hours : 6})
            }
            if(tokenData?.mobileVerified == 1 && verifyOtpData?.data?.resource?.accMobNumDetailsList?.length > 0){
                setOpenModal("multipleAccountMerge");
                setOpenModalData(verifyOtpData?.data?.resource?.accMobNumDetailsList || []);
            }else{
                setRefreshPopup(true);
            }
        }
    },[verifyOtpData?.isSuccess])

    useEffect(()=>{
        // Condition : For first time loging in inside this.
        if(refreshPopup){
            let response = sessionStorage.getItem("loginResponse");
            if(response){
                let loginResponse = JSON.parse(response);
                handleChangeModal(loginResponse);
            }
            setRefreshPopup(false);
        }
    },[refreshPopup])
    
    const callOtpApi = (type) => {
        dispatchHandler(dispatch, "getOtpRequest", {
            token : sessionStorage?.getItem("token"),
            source : type
        })
    }

    const handleChangeModal = (data) =>{
        if(tokenData?.mobileVerified == 0){
            setOpenModal("verifyMobile");
            callOtpApi("MOBILE");
        }else if( tokenData.emailVerified == 0){
            setOpenModal("verifyEmail");
            callOtpApi("EMAIL");
        }else if(data?.pendingSignup > 0){
            setOpenModal("vendorMerge");
        } 
        else{
            setOpenModal(false);
            setOpenModalData(null);
        }
    }

    const getChildComponent = () => {
        switch(openModal){
            case "verifyMobile" : 
            case "verifyEmail" : return EditEmail;
            case "vendorMerge" : return VendorMerge;
            case "fullPageLoader" : return FullPageLoader;
            case "multipleAccountMerge" : return VendorMerge;
        }
    }

    const logoutFunction = async () => {
        setOpenModal("fullPageLoader")
        dispatchHandler(dispatch, "userSessionCreateRequest", { token: sessionStorage.getItem('token') || "", flowType: "LOGGEDOUT" });
        deleteCookie('jwt')
        localStorage.clear();
        sessionStorage.clear();
        setTimeout(() => {
            setOpenModal(false);
            handleDocumentStatusChange();
        },1000);
    }

    const getParentChildProps = () => {
        let obj = {
            isCloseBlocked : ["verifyEmail", "verifyMobile"]?.includes(openModal) ? true : false,
            animationType: "center",
            postitionProps: { top: "20%", left: "30%", position: "absolute", borderRadius: 4 },
            dimensions: { width: "40vw", height: "60vh" },
            animationType: "center",
            closeModal : () => setOpenModal(false),
        }
        switch(openModal){
            case "verifyMobile" :
            case "verifyEmail":{
                return {
                    ...obj,
                    "type" : openModal=="verifyMobile" ? "mobile" : "email",
                    "flowName" : "verifyEmailOrMobile",
                }
            }
            case "vendorMerge" : {
                return {
                    ...obj,
                    dimensions: { width: "70vw", height: "80vh" },
                    postitionProps: { top: "10%", left: "15%", borderRadius: 4 },
                    skipModal : () => {
                        setOpenModal(false);
                    },
                    submitModal : () => {
                        logoutFunction();
                    }
                }
            }
            case "multipleAccountMerge":{
                return {
                    ...obj,
                    dimensions: { width: "70vw", height: "80vh" },
                    postitionProps: { top: "10%", left: "15%", borderRadius: 4 },
                    accMobNumDetailsList : openModalData,
                    multiAccountModal : true,
                    skipModal : () => {
                        setOpenModal(false);
                    },
                    submitModal : () => {
                        logoutFunction();
                    }
                }
            }
        }
    }

    if(openModal == "fullPageLoader"){
        return <FullPageLoader/>;
    }

    return (
        openModal && <ParentModal
            childComponent = {getChildComponent()}
            getParentChildProps = {getParentChildProps()}
            closeModal = {() => setOpenModal(false)}
        />
    )
}

export default DefaultPopUp