import React from 'react'

const UploadExcelSkeletons = (props) => {
    const renderSkeletons = () => {
        switch (props.step) {
            default: {
                return <div>
                    <h1>Skeleton Loading Needs to be implemented.</h1>
                </div>
            }
        }
    }
    return (
        renderSkeletons()
    )
}

export default UploadExcelSkeletons