import { CONFIG } from '../../config/index';
import { AUTH_CONFIG } from '../../authConfig';
const AXIOS_CONFIG = {
    headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic RXQ2MExXcFViTktwT0Vtc3pheEc="
    }
};
export const masterDbStore = [
    {
        title: "getVendorsListData",
        actionPrefix: 'getVendorsList',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.apiUrl;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload.apiUrl}`
        }
    },
    {
        title: "getMasterItemsData",
        actionPrefix: 'getMasterItems',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/vendorportal/managevendor/get/genericItemMaster`
        }
    },
    {
        title: "getMasterAgentsData",
        actionPrefix: 'getMasterAgents',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/vendorportal/managevendor/find/all/agent`
        }
    },
    {
        title: "sendVerificationEmailData",
        actionPrefix: 'sendVerificationEmail',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/user/vendor/enable/access/send/email`
        }
    },
    {
        title: "accessVendorPortalData",
        actionPrefix: 'accessVendorPortal',
        message: 'Request Completed Successfully',
        requestData: {
            token: false,
            buildUrl: (payload) => `${AUTH_CONFIG.BASE_URL}${AUTH_CONFIG.LOGIN}/retailer/vendor`,
            extraHeader: {
                "access-control-request-headers" : "content-type",
                "access-control-request-method" : "POST",
                "Page-Link":undefined
            }
        }
    },
    {
        title: "allowPortalAccessData",
        actionPrefix: 'allowPortalAccess',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/allow/portal/access`
        }
    },
    {
        title: "allowHoldUnholdVendorData",
        actionPrefix: 'allowHoldUnholdVendor',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/org/vendor/hold`
        }
    },
    {
        title: "sendVerificationLinkData",
        actionPrefix: 'sendVerificationLink',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/email/verification/vendor`
        }
    },
    {
        title: "getUserRolesListData",
        actionPrefix: 'getUserRolesList',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/get/roles`
        }
    },
    {
        title: "createNewVendorData",
        actionPrefix: 'createNewVendor',
        message: 'Request Completed Successfully',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload?.["apiUrl"];
                return payload
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload.apiUrl}`
        }
    },
    {
        title: "getMasterDataHsnCodeData",
        actionPrefix: 'getMasterDataHsnCode',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/pi/get/hsncode`
        }
    },
    {
        title: "getMasterDataArticleCodeData",
        actionPrefix: 'getMasterDataArticleCode',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/po/get/custom/article/hierarchy/data`
        }
    },
    {
        title: "getMasterDataItemCatDescData",
        actionPrefix: 'getMasterDataItemCatDesc',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/pi/get/itemcatdesc`
        }
    },
    {
        title: "uploadMasterDataImagesData",
        actionPrefix: 'uploadMasterDataImages', 
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/custom/multiple/file/upload`,
            extraHeader: {
                "Content-Type": "multipart/form-data",
            },
        }
    },
    {
        title: "createNewSiteData",
        actionPrefix: 'createNewSite',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.SITE}/create`
        }
    },
    {
        title: "editSiteData",
        actionPrefix: 'editSite',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.SITE}/update`
        }
    },
    {
        title: "getMasterDataCatDescData",
        actionPrefix: 'getMasterDataCatDesc',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/pi/get/catdesc/caption?hl3Code=${payload.hl4Code}&hl3Name=${payload.hl3Name}`,
            method: "GET",
        }
    },
    {
        title: "getDefaultMasterDataHsnCodeData",
        actionPrefix: 'getDefaultMasterDataHsnCode',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/pi/get/hsncode`
        }
    },
    {
        title: "createItemsData",
        actionPrefix: 'createItems',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/managevendor/create/item`
        }
    },
    {
        title: "resyncMasterItemsData",
        actionPrefix: 'resyncMasterItems',
        message: 'Items resynsc successfull!',
        errorMessage : "Failed to resync items data!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/managevendor/item/resync`
        }
    },
    {
        title: "updateMasterDataItemsStatusData",
        actionPrefix: 'updateMasterDataItemsStatus',
        message: 'Items status updated successfully!',
        errorMessage : "Failed to update items status!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.SO}/item/update/icodes`
        }
    },
    {
        title: "editMasterDataItemsData",
        actionPrefix: 'editMasterDataItems',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/so/item/update`
        }
    },
    {
        title: "getBulkEditItemData",
        actionPrefix: 'getBulkEditItem',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/vendorportal/managevendor/get/image/item/master`
        }
    },
    {
        title: "allRetailerData",
        actionPrefix: 'allRetailer',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.CORE_USER}/get/enterprises`
        }
    },
    {
        title: "allRolesData",
        actionPrefix: 'allRoles',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/role/get/all`
        }
    },
    {
        title: "saveSalesAgentsData",
        actionPrefix: 'saveSalesAgents',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/managevendor/save/salesAgent`
        }
    },
    {
        title: "addUserData",
        actionPrefix: 'addUser',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.CORE_USER}/create${payload?.sendEmail ? "/send/email" : ""}`
        }
    },
    {
        title: "deleteSalesAgentsData",
        actionPrefix: 'deleteSalesAgents',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/managevendor/delete/salesAgent`
        }
    },
    {
        title: "deleteCustomersData",
        actionPrefix: 'deleteCustomers',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/managevendor/delete/customer`
        }
    },
    {
        title: "accessCustomerPortalData",
        actionPrefix: 'accessCustomerPortal',
        message: 'Request Completed Successfully',
        requestData: {
            method: "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/customer/so/enable/customer/portal?slcode=${payload?.slCode === undefined ? '' : payload?.slCode}`
        }
    },
    {
        title: "deleteSelectedMaterialLibraryItemsData",
        actionPrefix: 'deleteSelectedMaterialLibraryItems',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/material/library/master/delete`
        }
    },
    {
        title: "getCreateTicketModuleListData",
        actionPrefix: 'getCreateTicketModuleList',
        message: 'Request Completed Successfully',
        responseData: {
            successDataHandler: (data) => data?.data
        },
        requestData: {
            method: "GET",
            extraHeader: AXIOS_CONFIG?.headers || {},
            buildUrl: (payload) => `https://supplymint.freshdesk.com/api/v2/admin/ticket_fields/${payload.id}`
        }
    },
    {
        title: "getCreateTicketIssuesListData",
        actionPrefix: 'getCreateTicketIssuesList',
        message: 'Request Completed Successfully',
        responseData: {
            // responseHandler: (data) => {
            //     return data?.data || {};
            // }
            successDataHandler: (data) => data?.data
        },
        requestData: {
            method: "GET",
            extraHeader: AXIOS_CONFIG?.headers || {},
            buildUrl: (payload) => `https://supplymint.freshdesk.com/api/v2/admin/ticket_fields/${payload.id}`
        }
    },
    {
        title: "saveCustomersData",
        actionPrefix: 'saveCustomers',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/managevendor/create/customer`
        }
    },
    {
        title: "updateCustomersRolesData",
        actionPrefix: 'updateCustomersRoles',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/update/roles/customer`
        }
    },
    {
        title: "userVendorEnableAccessData",
        actionPrefix: 'userVendorEnableAccess',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/user/vendor/enable/access`
        }
    },
    {
        title: "deleteTransportersData",
        actionPrefix: 'deleteTransporters',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/managevendor/delete/transporter`
        }
    },
    {
        title: "updateTransportersRolesData",
        actionPrefix: 'updateTransportersRoles',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/update/roles/transporter`
        }
    },
    {
        title: "saveTransportersData",
        actionPrefix: 'saveTransporters',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/managevendor/create/transporter`
        }
    },
    {
        title: "updateAgentsRolesData",
        actionPrefix: 'updateAgentsRoles',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/update/roles/agent`
        }
    },
    {
        title: "updateAgentData",
        actionPrefix: 'updateAgent',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${CONFIG.VENDORPROTAL}/managevendor/update/agent`
        }
    },
    {
        title: "createNewTicketData",
        actionPrefix: 'createNewTicket',
        message: 'Request Completed Successfully',
        responseData: {
            successDataHandler: (data) => data?.data
        },
        requestData: {
            extraHeader: AXIOS_CONFIG?.headers || {},
            buildUrl: (payload) => `https://supplymint.freshdesk.com/api/v2/tickets`
        }
    },
    {
        title: "updateVendorRolesData",
        actionPrefix: 'updateVendorRoles',
        message: 'Request Completed Successfully',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/update/roles/vendor`
        }
    },
    {
        title: "createNewTicketWithAttachmentsData",
        actionPrefix: 'createNewTicketWithAttachments',
        message: 'Request Completed Successfully',
        responseData: {
            successDataHandler: (data) => data?.data
        },
        requestData: {
            extraHeader: AXIOS_CONFIG?.headers ? {
                ...AXIOS_CONFIG?.headers,
                "Content-Type": "multipart/form-data"
            } : {},
            buildUrl: (payload) => `https://supplymint.freshdesk.com/api/v2/tickets`
        }
    },
    {
        title: "updateMaterialLibraryData",
        actionPrefix: 'updateMaterialLibrary',
        message: 'Material library items added successfully!',
        errorMessage : "Failed to add material library items!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}${payload?.id ?  "/material/library/master/update" : "/material/library/create"}`
        }
    },
    {
        title: "uploadBulkItemsData",
        actionPrefix: 'uploadBulkItems',
        message: 'Item Uploaded successfully!',
        errorMessage : "Failed to upload items!",
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/admin/items/upload/bulk/items`,
            extraHeader: {
                "Content-Type": "multipart/form-data",
            },
        }
    },
    {
        title: "approveVendorOnboardingData",
        actionPrefix: 'approveVendorOnboarding',
        message: 'Vendor Onboarding Request Updated successfully!',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/vendorportal/managevendor/update/vendor/request`
        }
    },
    {
        title: "inactiveExternalUserData",
        actionPrefix: 'inactiveExternalUser',
        message: 'Request Updated successfully!',
        requestData: {
            updatePayload: ({ ...payload }) => {
                delete payload.type;
                return payload;
            },
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/user/inactive/user/${payload.type}`
        }
    },
    {
        title: "getAnnoucementUsersListData",
        actionPrefix: 'getAnnoucementUsersList',
        message: '',
        requestData: {
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/org/get/user/list`
        }
    },
    {
        title: "getAnnoucementDetailsData",
        actionPrefix: 'getAnnoucementDetails',
        message: 'Failed to retrieve announcement details!',
        requestData: {
            method : "GET",
            buildUrl: (payload) => `${CONFIG.BASE_URL}/core/org/get/announcement/detail/${payload?.id}`
        }
    },
]