import { dispatchHandler } from "../../../helper/apiHelperFunctions";
import { parseJwt } from "../../../helper/genericFunction";
export const loginRequest = (config) => {
    let {
        username,
        password, 
        dispatch, 
        location,
        setLoading,
        forceLogin,
        mfaPasscode,
        token
    } = config || {};
    setTimeout(function () {
        setLoading?.(true)
        let pass = btoa(password);
        let loginData = token ?  {
            token : token
        } : {
            uType: '',
            username: btoa(username),
            password: pass,
            hash: "true",
            mfaPasscode : mfaPasscode ? mfaPasscode : undefined,
            forceLogin : forceLogin ? 1 : undefined
        }
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        console.log("pass word897654", params, window.location.search);
        if (params.client_id && params.nonce && params.redirect_uri && params.state) {
            loginData.clientId = params.client_id;
            loginData.redirectUrl = params.redirect_uri;
            loginData.thirdPartyNonce = params.nonce;
            loginData.thirdPartyState = params.state;
        }
        dispatchHandler(dispatch, "loginRequest", loginData);
    }, 50)
}

// Function : To handle the response of the login api and open the pending sign up modal
export const checkPendingSignUpCheck = (data) => {
    let tokenData = parseJwt(data?.token);
    if (tokenData?.functionType === "ENT_SET_CREDENTIALS" || tokenData?.functionType === "VATC_SET_CREDENTIALS") {
        sessionStorage.setItem("loginTempInfo", JSON.stringify(data));
        return true;
    }
    return false
}