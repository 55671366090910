import Icons from "../assets/icons";

export function getDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd
    }
    if (mm < 10) {
        mm = '0' + mm
    }
    let date = yyyy + '-' + mm + '-' + dd
    return date;
}

// Set the token in cookie
export function setCookie({name, value, hours}) {
    console.log("check token",value )
    const date = new Date();
    date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();

    let i = 1;
    while(value){
        let val;
        if(value?.length >=4000){
            val =  value?.substring(4000*(i-1), 4000*i);
        }else{
            val = value;
        }
        document.cookie = `${name}${i}=${val}; ${expires}; path=/; Secure; SameSite=Strict`;
        value = value?.substring(4000*i);
        i++;
    }
}

// Get the token from a cookie
export function getCookie(name) {
    const value = `; ${document.cookie}`;
    let token = "";

    let i = 1;

    while(value){
        const parts = value?.split(`; ${name}${i}=`);
        if (parts.length === 2) token += parts.pop().split(';').shift();
        else break;

        i++;
    }
    return token == "" ? null : token;
}

// Delete the token from a cookie
export function deleteCookie(name) {
    const value = `; ${document.cookie}`;
    let i = 1;
    while(value){
        const parts = value?.split(`; ${name}${i}=`);
        if (parts.length === 2)  document.cookie = `${name}${i}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=Strict`;
        else break;
        i++
    }
}

export function getDateddmmyy() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd
    }
    if (mm < 10) {
        mm = '0' + mm
    }
    let date = dd + '-' + mm + '-' + yyyy
    return date;
}

export function get_mmddyy(value) {
    let dd = value.split('-')[0]
    let mm = value.split('-')[1]
    let yy = value.split('-')[2]
    let date = mm + '-' + dd + '-' + yy
    return date

}

export function getTime() {
    var today = new Date();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    return time;
}

export function getMonth() {
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var now = new Date();
    let month = months[now.getMonth()]
    return month;
}

export function daysInMonth(month, year) {
    var no = new Date(year, month, 0).getDate().toString();
    return no;
}

export function getYear() {
    var d = new Date();
    var n = d.getFullYear();
    return n;
}

export function getMonthCount() {
    var d = new Date();
    var n = d.getMonth();
    return n + 1;
}

export function getMonthByNumber(count) {
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let month = months[count - 1]
    return month;
}

export function openMyDrop(e) {
    var nameOfClass = "dropdown-content";
    document.getElementById("dropMenu").classList.add(nameOfClass);
}

export function closeMyDrop(e) {
    var nameOfClass = "dropdown-content";
    document.getElementById("dropMenu").classList.remove(nameOfClass);
}

export function FilterData(search, data) {
    return data
        .filter(data => {
            if (data.site.toLowerCase().includes(search.toLowerCase())) {
                return true;
            }
            return false;
        })
}

export function handleChange(e) {
    var panel = document.getElementById("scrollDiv");
    var total = panel.scrollWidth - panel.offsetWidth;
    var percentage = total * ((e.target.value * 2) / 100);
    panel.scrollLeft = percentage;
    if (e.target.value == 1) {
        panel.scrollLeft = 0
    }
    var rangeVal = e.target.value
    return { rangeVal, total }
}

export function handleChangeModal(e) {
    var panel = document.getElementById("scrollDivModal");
    var total = panel.scrollWidth - panel.offsetWidth;
    var percentage = total * ((e.target.value * 2) / 100);
    panel.scrollLeft = percentage;
    if (e.target.value == 1) {
        panel.scrollLeft = 0
    }
    var rangeVal = e.target.value
    return { rangeVal, total }
}


export function parseJwt(token) {
    if (token) {
        var base64Url = token?.split('.')[1];
        var base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64 || "")?.split('')?.map(function (c) {
            return '%' + ('00' + c?.charCodeAt(0)?.toString(16))?.slice(-2);
        })?.join(''));
        return JSON.parse(jsonPayload || "{}");
    }
    return {};
};

export const decodeToken = () => {
    var token = sessionStorage.getItem('token')
    let decode = token != "" ? parseJwt(token) : {};
    return decode
}

export function genericDateFormat(format) {
    var requiredFormat = format != undefined && (format.split(/[\H']+/)[0]).toUpperCase();
    requiredFormat = requiredFormat?.replace("E", "ddd")
    return requiredFormat
}

export function convertCamelCase(camelCase) {
    const result = camelCase?.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult
}

export function emailVerifyRegex(value) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return value.match(emailRegex)
}

export function uptoTwoDecimalRegex(value) {
    var regex = /^-?[0-9]\d*\.?\d{0,2}$/
    if (value?.length == 0) {
        return true
    }
    return regex.test(value)
}

export function allowOnlyNumbers(value) {
    var regex = /^[\d]*$/
    return regex.test(value)
}

export function setToken(resource) {
    sessionStorage.setItem('token', resource.token);
    // sessionStorage.setItem('roles', JSON.stringify(resource.roles));
    // sessionStorage.setItem('modules', JSON.stringify(resource.modules["0"]));
}

export function parseJSON(string, returnOriginal = false) {
    let parsedJSON = "";
    try {
        parsedJSON = JSON.parse(string);
        return parsedJSON;
    }
    catch (e) {
        if (returnOriginal) {
            return string;
        }
        return null;
    }
}

//check pincode under regex ^[1-9][0-9]{5}$
export function validatePincode(val) {
    return val != "" && val.match(/^[1-9][0-9]{5}$/) ? true : false;
}

export function changeKey(key) {
    if (key.includes("order")) return "orderNumber"
    else if (key.includes("shipment")) return "shipmentAdviceCode"
    else if (key.includes("logistic")) return "logisticNumber"
    else if (key.includes("set")) return "setBarCode"
    else if (key.includes("item")) return "itemCode"
    return key;
}

export function purchaseHistoryPayload(search) {
    let payloadForHistory = {};
    const urlSearchParams = new URLSearchParams(search)
    const params = Object.fromEntries(urlSearchParams.entries());
    Object.keys(params).forEach(key => {
        params[key] = JSON.parse(decodeURIComponent(params[key]));
    });
    payloadForHistory["type"] = params.type ? Number(params.type) : 1
    payloadForHistory["sortedBy"] = params.sortedBy ? params.sortedBy : "";
    payloadForHistory["sortedIn"] = params.sortedIn ? params.sortedIn : "";
    payloadForHistory["filter"] = params.filter ? params.filter : {};
    payloadForHistory["search"] = params.search ? params.search : "";
    payloadForHistory["filterItems"] = params.filterItems ? params.filterItems : {};
    payloadForHistory["pageNo"] = params.filterItems ? params.pageNo : 1;
    payloadForHistory["pageSize"] = params.filterItems ? params.pageSize : 10;
    return payloadForHistory
};


export function findIndexOfObjectInArray(array, key, value) {
    return array.findIndex(item => item[key] == value)
}

export function convertToMB(data) {
    const megabytes = data / (1024 * 1024);
    return megabytes.toFixed(2);
}

export function getHomeLink(dashboardModules) {
    // let dashboardModules = [...this.state.dashboardModules];
    if (dashboardModules?.length !== 0) {
        let pinnedModules = localStorage.getItem(parseJwt(sessionStorage.getItem('token')).jti), pageUrl = "";
        if (pinnedModules) {
            let id = Number(pinnedModules.split(",")[0]);
            pageUrl = dashboardModules.find(item => item.id == id).pageUrl?.replace("#", "");
        }
        else {
            pageUrl = dashboardModules[0].pageUrl?.replace("#", "");
        }
        return pageUrl;
    }
    return "/home";
}

export function renderFilterTags(appliedFilters = {}, headersData = {}, headersType = "Default Headers", applyFilter, removeFilter) {
    try {
        if (Object.keys(appliedFilters).length === 0)
            return null;
        return (
            <div className="show-applied-filter">
                <button type="button" className="saf-clear-all" onClick={() => applyFilter({})}>Clear All</button>
                {Object.keys(appliedFilters).map((key) =>
                    <button type="button" className="saf-btn">{headersData[headersType][key]}
                        <Icons.ClearSearch onClick={() => removeFilter(key)} />
                        <span className="generic-tooltip">{(() => {
                            switch (headersData.columnType[key]) {
                                case "NUMBER":
                                case "TIME": {
                                    return `From ${appliedFilters[key].from} to ${appliedFilters[key].to}`;
                                }
                                case "DATE": {
                                    if (!appliedFilters[key].key)
                                        return `From ${appliedFilters[key].from} to ${appliedFilters[key].to}`;
                                    else if (appliedFilters[key].key === "SM_DATE_TODAY_VALUE")
                                        return "Today";
                                    else if (appliedFilters[key].key === "SM_DATE_YESTERDAY_VALUE")
                                        return "Yesterday";
                                    else if (appliedFilters[key].key === "SM_DATE_LAST7DAYS_VALUE")
                                        return "Last 7 Days";
                                    else if (appliedFilters[key].key === "SM_DATE_LASTWEEK_VALUE")
                                        return "Last Week";
                                    else if (appliedFilters[key].key === "SM_DATE_THISMONTH_VALUE")
                                        return "This Month";
                                    else if (appliedFilters[key].key === "SM_DATE_LASTMONTH_VALUE")
                                        return "Last Month";
                                    return "";
                                }
                                default: {
                                    if (appliedFilters[key] == "SM_TEXT_EMPTY_VALUE")
                                        return "is Empty";
                                    else if (appliedFilters[key] == "SM_TEXT_NOT_EMPTY_VALUE")
                                        return "is Not Empty";
                                    else
                                        return appliedFilters[key];
                                }
                            }
                        })()}</span>
                    </button>
                )}
            </div>
        );
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

// other  data function 


export function getFiltersOtherData(mainKeys, validKeys) {
    const otherData = {};
    validKeys?.map((key) => {
        if (mainKeys && mainKeys[key]) {
            otherData[key] = Object.keys(mainKeys[key]);
        }
    });
    return otherData;
}


export function getFileIcon (fileName) {
    const extension = fileName?.split('.')?.pop()?.toLowerCase();
    switch (extension) {
    case 'pdf':
        return 'PDF'; // Path to your PDF icon
    case 'mp4': case 'avi': case 'mkv': case 'mov': case 'wmv': case 'flv': case 'webm': case 'mpeg': case 'mpg': case '3gp': case 'ogv':
        return 'VIDEO'; // Path to your video icon
    case 'xlsx': case 'xls': case 'xlsm': case 'xlsb': case 'xltx': case 'xltm':
        return 'EXCEL'; // Path to your image icon
    // Add more cases as needed
    default:
        return null; // Path to your default icon
    }
};