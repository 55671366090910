import React, { useEffect, useState } from 'react'
import { handleChange, parseJwt } from '../../../../helper/genericFunction';
import GenericCustomButton from '../../../../genericComponents/GenericCustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchHandler } from '../../../../helper/apiHelperFunctions';
import UserProfileSkeletonLoader from '../../../skeletons/UserProfileSkeletonLoader';
import GenericInput from '../../../../genericComponents/input/GenericInputComponent';
import Icons from '../../../../assets/icons';
import ParentModal from '../../../../genericComponents/ParentModal'
import EditEmail from './EditEmail';
import LanguageTranslator from '../../../../locals/LanguageTranslator';

// Note  ->  Doc

// Edit Email Component is getting used to edit the Mobile number and the email account 
// based on the type == "mobile" or type == "email".

// ------------------------- //

const ProfileDetails = (props) => {
    const [editMode, setEditMode] = useState(false);
    const tokenData = parseJwt(sessionStorage?.getItem("token")) || {};
    const authStore = useSelector(state => state.authStore);
    const dispatch = useDispatch();
    const [profileData, setProfileData] = useState({});

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [language, setLanguage] = useState("");
    const [country, setCountry] = useState("");

    const [selectedModal, setSelectedModal] = useState(false);
    const [countriesData, setCountriesData] = useState([]);

    useEffect(() => {
        fetch("https://restcountries.com/v3.1/all")
            .then(response => response.json())
            .then(data => {
                const countries = data.map(country => ({
                    key: country.cca2, // 2-letter country code
                    value: country.name.common // Country name
                }));
                console.log(countries); // Output the array of countries
                setCountriesData(countries);
            })
            .catch(error => console.error('Error fetching country data:', error));

        if (authStore?.["getUserProfileData"]?.["data"]?.resource) {
            setProfileData(authStore?.["getUserProfileData"]?.["data"]?.resource || {})
        } else {
            dispatchHandler(dispatch, "getUserProfileRequest")
        }

    }, [])

    console.log("Countreis data", countriesData);

    useEffect(() => {
        setFirstName(profileData?.["firstName"]);
        setLastName(profileData?.["lastName"]);
        setLanguage(profileData?.["language"]);
        setCountry(profileData?.["country"]);
    }, [profileData])

    useEffect(() => {
        if (authStore?.["getUserProfileData"]?.isSuccess) {
            setProfileData(authStore?.["getUserProfileData"]?.data?.resource || {});
        }
    }, [authStore?.["getUserProfileData"]?.isSuccess])

    useEffect(() => {
        if (authStore?.["editUserData"]?.isSuccess || authStore?.["verifyOtpData"]?.isSuccess) {
            dispatchHandler(dispatch, "getUserProfileRequest")
            setEditMode(false)
            setSelectedModal(false);
            if (authStore?.["editUserData"]?.isSuccess) {
                if(localStorage?.getItem("CURRENT_LANGUAGE") != language){
                    localStorage?.setItem("CURRENT_LANGUAGE", language);
                    window.location.reload();
                }
            }
        }
    }, [authStore?.["editUserData"]?.isSuccess, authStore?.["verifyOtpData"]?.isSuccess])

    // Reset Data After Success of the Verify otp api
    useEffect(() => {
        if (authStore?.["verifyOtpData"]?.isSuccess) {
            dispatchHandler(dispatch, "getUserProfileRequest")
            setEditMode(false)
            setSelectedModal(false);
        }
    }, [authStore?.["verifyOtpData"]?.isSuccess])

    const handleChange = (type, data) => {
        switch (type) {
            case "editProfile": {
                dispatchHandler(dispatch, "editUserRequest", {
                    firstName: firstName,
                    lastName: lastName,
                    language: language,
                    country: country
                })
                break;
            }
            case "editemail": {
                setSelectedModal("editEmail");
                break;
            }
            case "editphone": {
                setSelectedModal("editMobileNumber");
                break;
            }
        }
    }

    const getParentChildProps = () => {
        let payload = {
            closeModal: () => { setSelectedModal("") },
            animationType: "center",
            postitionProps: { top: "25%", left: "25%", position: "absolute", margin: 0, borderRadius: 4 },
            dimensions: { width: "50vw", height: "50vh" },
            animationType: "center",
            popUpOpenedFromProfile: true
        }

        switch (selectedModal) {
            case "editEmail": {
                return {
                    ...payload,
                    type: "email",
                }
            }
            case "editMobileNumber": {
                return {
                    ...payload,
                    type: "mobile"
                }
            }
        }
    }

    const getChildComponent = () => {
        switch (selectedModal) {
            case "editEmail":
            case "editMobileNumber": {
                return EditEmail;
            }
            // case "editMobileNumber" :{
            //     return EditMobileNumber;
            // }
        }
    }

    let lang = {
        "en": "English",
        "zh": "Chinese ( 中国人 )",
        "en-HS" : "English-HS"
    }


    return (
        authStore?.["getUserProfileData"]?.isLoading ? <UserProfileSkeletonLoader /> : <>
            {/* Render the profile main details */}
            <div className="upr-info">
                <div className="upri-name">
                    <span>{tokenData?.firstName == null ? "" : tokenData?.firstName?.charAt(0)}</span>
                </div>
                <div className="upri-det">
                    <h3><LanguageTranslator>{profileData?.firstName}</LanguageTranslator> <LanguageTranslator>{profileData.lastName}</LanguageTranslator></h3>
                    <LanguageTranslator tag="p">{profileData.email}</LanguageTranslator>
                    {!editMode && <GenericCustomButton
                        sendEventAnalyticsToGoogle = {true}
                        type="primary"
                        label="Edit Profile"
                        onClick={() => setEditMode(true)}
                    />}
                </div>

            </div>

            <div className='upr-det'>
                {/* First Name Input Field */}
                <div className='uprd'>
                    <LanguageTranslator tag="label">First Name</LanguageTranslator>
                    {editMode ? <GenericInput
                        key="firstName"
                        onChange={(e) => setFirstName(e?.target?.value || "")}
                        value={firstName}
                        dependentFieldsObject={["firstName"]}
                    /> : <input type='text' autoComplete="off" value={profileData?.["firstName"]} disabled={true} />}
                </div>

                {/* Last Name Input Field */}
                <div className='uprd'>
                    <LanguageTranslator tag="label">Last Name</LanguageTranslator>
                    {editMode ? <GenericInput
                        key="lastName"
                        onChange={(e) => setLastName(e?.target?.value || "")}
                        value={lastName}
                        dependentFieldsObject={["lastName"]}
                    /> : <input type='text' autoComplete="off" value={profileData?.["lastName"]} disabled={true} />}
                </div>
                {
                    [
                        { "key": "email", label: "Email", },
                        { "key": "phone", label: "Mobile No" },
                        { "key": "enterpriseId", label: "Enterprise Id" },
                        { "key": "organisationId", label: "Organisation Id" },
                        { "key": "username", label: "Username" },
                        { "key": "uType", label: "User Type" },
                        { "key": "uCode", label: "User Code" },
                    ]?.map(item => (
                        <div className='uprd'>
                            <label className='flex items-center justify-between'><LanguageTranslator>{item?.label}</LanguageTranslator>
                                <span onClick={() => handleChange(`edit${item?.key}`)}>{["email", "phone"]?.includes(item?.key) && <Icons.EditIcon />}</span>
                            </label>
                            <input disabled={true} value={item?.value || profileData?.[item?.key]} type='text' />
                        </div>
                    ))
                }
                <div className='uprd'>
                    <LanguageTranslator tag="label">Language</LanguageTranslator>
                    {editMode ? <GenericInput
                        key="language"
                        type="dropdown"
                        iconRight={<Icons.DownArrowNew />}
                        onChange={(val) => setLanguage(val?.key)}
                        value={lang?.[language]}
                        dependentFieldsObject={["language"]}
                        inputProps={() => ({
                            headerKeys: {
                                "lang": {}
                            },
                            itemKeys: {
                                "lang": ["lang"]
                            },
                            getListData: () => (
                                [{ key: "en", lang: "English" }, { key: "zh", lang: "Chinese ( 中国人 )" }, {key : "en-HS", lang : "English-HS"}]
                            )
                        })}
                    /> : <input type='text' autoComplete="off" value={lang?.[profileData?.["language"]] || ""} disabled={true} />}
                </div>

                <div className='uprd countryDropdown'>
                    <LanguageTranslator tag="label">Country</LanguageTranslator>
                    {editMode ? <GenericInput
                        key="language"
                        type="dropdown"
                        iconRight={<Icons.DownArrowNew />}
                        onChange={(val) => setCountry(val?.key)}
                        value={countriesData?.find(item => item?.key === country)?.value || ""}
                        dependentFieldsObject={["language"]}
                        inputProps={() => ({
                            headerKeys: {
                                "value": {}
                            },
                            itemKeys: {
                                "value": ["value"]
                            },
                            getListData: () => (
                               countriesData
                            ),
                            search :{
                                placeholder: "Type To Search",
                                isSearchIconRequire: true,
                                customSearchFunction: (listState, listFunctions) => {
                                    const { listData, currentSearch } = listState;
                                    return listData?.filter(item => item?.["value"]?.toLowerCase().includes(currentSearch?.toLowerCase()))
                                }
                            }
                        })}
                    /> : <input type='text' autoComplete="off" value={ countriesData?.find(item => item?.key === profileData?.["country"])?.value || ""} disabled={true} />}
                </div>

                {/* Final Submit Buttons */}
                {editMode &&
                    <div className='uprd-bot'>
                        <GenericCustomButton
                            label={"Cancel"}
                            onClick={() => setEditMode(false)}
                            type='default'
                        />
                        <GenericCustomButton
                            label="Save"
                            type="primary"
                            disabled={!firstName || !lastName || !language || !country }
                            onClick={() => handleChange("editProfile")}
                        />
                    </div>
                }
                {selectedModal && <ParentModal
                    getParentChildProps={getParentChildProps()}
                    childComponent={getChildComponent()}
                    closeModal={() => { setSelectedModal(false) }}
                />}

            </div>
        </>
    )
}

export default ProfileDetails