import React, { useEffect, useState } from 'react'
import GenericCustomButton from '../../../genericComponents/GenericCustomButton'
import Icons from '../../../assets/icons'
import ParentModal from '../../../genericComponents/ParentModal'
import NewOnboardingRequestModal from '../../../authComponents/vendorOnboarding/components/NewOnboardingRequestModal'
import { getEnterprises } from '../../../helper/helperTableFunctions'
import { parseJwt } from '../../../helper/genericFunction'
import MultiColorCircularProgress from '../../../authComponents/vendorOnboarding/components/MultiColorCircularProgress'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchHandler } from '../../../helper/apiHelperFunctions'
import LanguageTranslator from '../../../locals/LanguageTranslator'

const NewRegisterVendorDashbaord = (props) => {

    const dispatch = useDispatch()
    const authStore = useSelector((state) => state.authStore)


    let { type = "home" } = props || {}

    let uType = parseJwt(sessionStorage.getItem("token"))?.uType?.toUpperCase();

    const initialFaqData = [
        {
            id: 1,
            question: 'Why Collaborate with Multiple Retailers?',
            answer: 'Broaden Your Reach: By partnering with various retailers, you can introduce your products to a diverse and extensive customer base. Broaden Your Reach: By partnering with various retailers, you can introduce your products to a diverse and extensive customer base.',
            isExpanded: false,
        },
        {
            id: 2,
            question: 'Getting Started on SupplyMint',
            answer: 'Broaden Your Reach: By partnering with various retailers, you can introduce your products to a diverse and extensive customer base. Broaden Your Reach: By partnering with various retailers, you can introduce your products to a diverse and extensive customer base. Broaden Your Reach: By partnering with various retailers, you can introduce your products to a diverse and extensive customer base. Broaden Your Reach: By partnering with various retailers, you can introduce your products to a diverse and extensive customer base. ',
            isExpanded: false,
        },
        {
            id: 3,
            question: 'Support and Resources',
            answer: 'Broaden Your Reach: By partnering with various retailers, you can introduce your products to a diverse and extensive customer base. Broaden Your Reach: By partnering with various retailers, you can introduce your products to a diverse and extensive customer base. Broaden Your Reach: By partnering with various retailers, you can introduce your products to a diverse and extensive customer base. Broaden Your Reach: By partnering with various retailers, you can introduce your products to a diverse and extensive customer base. ',
            isExpanded: false,
        },
    ]

    const [selectedModal, setSelectedModal] = useState("");
    const [faqData, setFaqData] = useState(initialFaqData)
    const [percenatgeData, setPercentageData] = useState({
        getModulePercentageHeaderWise: null,
        getTotalModulePercentage: null,
        totalOnboardingPercentage: null
    })

    const enterprisesData = getEnterprises()

    // calling the percentage apii
    useEffect(() => {
        if(uType != "ENT"){
            dispatchHandler(dispatch, 'getVendorOnboardingPercentageRequest')
        }
    }, [])

    // setting the percentage api data 
    useEffect(() => {
        if (authStore?.getVendorOnboardingPercentageData?.isSuccess) {
            setPercentageData(authStore?.getVendorOnboardingPercentageData?.data)
        }
    }, [authStore?.getVendorOnboardingPercentageData?.isSuccess])

    const handleChange = (type, config = {}) => {
        let { } = config;

        switch (type) {
            case "startNewBusiness": {
                setSelectedModal("startNewBusiness")
                break;
            }
        }

    }
    console.log("Handle change", selectedModal);

    const getParentChildProps = () => {
        switch (selectedModal) {
            case "startNewBusiness": {
                return {
                    closeModal: () => { setSelectedModal(false) },
                    animationType: 'moveUp',
                    // postitionProps: { top: "20%", left: "25%", position: "fixed", borderRadius: 4 },
                    postitionProps: { top: "50%", left: "50%", position: "fixed", transform: 'translate(-50%, -50%)', borderRadius: 0 },
                    dimensions: { minWidth: "400px", maxWidth: '460px', height: 'fit-content', maxHeight: '90vh', overflowY: 'auto' },
                }
            }
        }
    }

    const getChildComponent = () => {

        switch (selectedModal) {
            case "startNewBusiness": return NewOnboardingRequestModal
        }
    }

    if ((enterprisesData?.length > 0 && enterprisesData?.[0]?.eid != -1) || uType != "VENDOR") {
        return null;
    }

    switch (type) {
        case "home":

            const userData = parseJwt(sessionStorage.getItem("token"));
            const formattedDate = moment().format('dddd, DD MMM YYYY');

            const toggleExpandRow = (obj) => {
                const newFaqData = faqData?.map((el) => {
                    if (el?.id === obj?.id) {
                        return { ...el, isExpanded: !el?.isExpanded };
                    }
                    return { ...el, isExpanded: false };
                })
                setFaqData(newFaqData);
            }

            return (
                <React.Fragment>
                    <div className='vendor-onboarding-dashboard flex items-start gap-3 p-[12px] text-[#0F172A] relative'>
                        <div className='flex-1 bg-white space-y-6 ml-4 px-4 pt-[1rem] pb-4 sticky top-[92px]'>
                            <div>
                                <h2 className='font-semibold text-2xl'>Hello, {userData?.firstName + " " + userData?.lastName}</h2>
                                <p className='bg-[#F3F4F9] text-xs text-[#67768E] px-3 py-1 w-fit'>Its {formattedDate ?? ''}</p>
                            </div>
                            <div className='flex items-center gap-3 p-[12px] bg-[#2969FF1A]'>
                                <div className='px-1'>
                                    <Icons.CelebrationPopperIcon />
                                </div>
                                <div className='space-y-1'>
                                    <p className='font-semibold text-lg'>Congratulations on your new partnership with Us!</p>
                                    <p className='text-sm'>You can now expand your business with multiple brands with support of supplymint on every step.</p>
                                </div>
                            </div>
                            <div>
                                <p className='text-[18px]'>Take a look at all the benefits you'll get with SupplyMint below.</p>
                                <div className='px-4 py-1 divide-y divide-[#ECEDF1] bg-[#F8F9FB]'>
                                    {
                                        faqData?.length > 0 && faqData.map((obj, index) => (
                                            <div
                                                key={obj?.id}
                                                className={`pb-[0.3rem]`}
                                            >
                                                <div
                                                    className='cursor-pointer pt-[15px] pb-3 flex items-start justify-between'
                                                    onClick={() => toggleExpandRow(obj)}
                                                >
                                                    <p className='font-semibold text-sm mb-0'>{obj?.question}</p>
                                                    <div
                                                        // className={`expand-collapse-icon mx-2 h-[20px] w-[20px] overflow-hidden`}
                                                        className={`expand-collapse-icon mx-2 h-[20px] w-[20px] overflow-hidden`}
                                                    >
                                                        <div className={`relative`}>
                                                            {/* <span className={`delay-100 duration-200 ${obj?.isExpanded ? 'opacity-100' : 'opacity-0'}`}> */}
                                                            <span className={``}>
                                                                <Icons.MinusIconBlack />
                                                            </span>
                                                            {/* <span className={`delay-100 duration-200 ${!obj?.isExpanded ? 'opacity-100' : 'opacity-0'}`}> */}
                                                            <span className={`absolute z-10 top-[0] left-[0] duration-200 ${!obj?.isExpanded ? 'scale-100' : 'scale-y-0'}`}>
                                                                <Icons.PlusIconBlack />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`w-3/4 text-xs h-full overflow-hidden duration-200 ${obj?.isExpanded ? 'pb-3 max-h-[500px]' : 'max-h-[0]'}`}>
                                                    <span>{obj?.answer}</span>
                                                </div>
                                            </div>

                                        )
                                        )
                                    }
                                </div>
                                <GenericCustomButton
                                    label="Start Your Business"
                                    type="primary"
                                    // rightIcon={<Icons.RightArrow2 />}
                                    onClick={() => handleChange("startNewBusiness")}
                                    className={'mt-4 !rounded-none !h-[35px] !px-5'}
                                />
                            </div>
                        </div>
                        <div className='max-w-xs space-y-3'>
                            <div className='bg-white pt-4 pb-5 text-[15px]'>
                                <h2 className='font-bold px-4 pb-1 uppercase'>Onboarding Statistics</h2>
                                <hr className='text-[#ECEDF1]' />
                                <div className='px-4 pt-[16px] space-y-3'>
                                    <div><Icons.TimeLeftIcon /></div>
                                    <p className='font-medium leading-5'>You have completed {percenatgeData?.totalOnboardingPercentage ?? 0}% of required details</p>
                                    <p className='text-xs'>Please complete your all pending details to avail full benefits of supplymint vendor portal.</p>
                                </div>
                            </div>
                            <div className='bg-white py-4'>
                                <div className='px-4 pb-1 text-center'>
                                    <h2 className='font-bold text-[15px] mb-1'>{userData?.ename ?? ''}</h2>
                                    <p className='text-sm'>{userData?.firstName + " " + userData?.lastName}</p>
                                </div>
                                <hr />
                                {
                                    percenatgeData?.totalOnboardingPercentage === null ?
                                        (
                                            <div className='flex items-center flex-col p-15'>
                                                <div className='generic-skeleton wid-120 hgt-120 m-bot-15' style={{ borderRadius: "50%" }}></div>
                                                <div className='generic-skeleton wid-50'></div>
                                                <div className='generic-skeleton wid-150 m-0'></div>
                                                <div className='generic-skeleton wid-200 hgt-35 m-top-20 m-0'></div>
                                            </div>
                                        ) : (<div className='px-4 pb-4 mt-6 flex flex-col items-center justify-center gap-4 text-center'>
                                            <div className='voblb-body'>
                                                <MultiColorCircularProgress
                                                    percentage={percenatgeData?.totalOnboardingPercentage ?? 0}
                                                    strokeWidth={3}
                                                />
                                            </div>
                                            <div>
                                                <p className='text-2xl font-bold mb-0'>{percenatgeData?.totalOnboardingPercentage ?? 0}%</p>
                                                <p className='text-[13px]'>Overall Completion</p>
                                            </div>
                                            <GenericCustomButton
                                                label="Complete Now"
                                                // rightIcon={<Icons.RightArrow2 />}
                                                // onClick={() => handleChange("startNewBusiness")}
                                                className={'mt-0 border border-[1.5px] border-[#0F172A] bg-none rounded-none px-10 py-1.5 text-[#0F172A] text-sm font-semibold hover:bg-[#0F172A]/5'}
                                            />
                                        </div>)
                                }
                            </div>
                        </div>
                    </div>
                    {
                        selectedModal && <ParentModal
                            closeModal={() => setSelectedModal("")}
                            getParentChildProps={getParentChildProps()}
                            childComponent={getChildComponent()}
                        />
                    }
                </React.Fragment>
            )

        case "header":

            return (
                <div className='flex items-center gap-2 px-2 py-1.5 my-1.5 bg-[#FEF5E3] hover:opacity-75 cursor-pointer text-xs font-medium' onClick={() => handleChange("startNewBusiness")}>
                    <div>
                        <Icons.PlaneIconYellow />
                    </div>
                    <LanguageTranslator tag='p' className="text-[#A4780E] mb-0">Explore Brands & Start your business with them</LanguageTranslator>
                </div>
            )
    }
}

export default NewRegisterVendorDashbaord