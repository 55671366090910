import React from 'react'
import IconsPath from '../../../../assets/icons';
import GenericCustomButton from '../../../GenericCustomButton';

const TableComponentSorting = (props = {}) => {

    let {actionBarConfig} = props;
    
    return (
        actionBarConfig && actionBarConfig.sorting && (
            <GenericCustomButton
                className={
                    props.sortingModal == true
                        ? "gen-action-btn gen-action-btn-focus"
                        : "gen-action-btn"
                }
                onClick={props.OpenSortingModal}
                loading={false}
                disabled={actionBarConfig.sorting.disabled}
                leftIcon={<IconsPath.BreadcrumbSortingIcon />}
                label={<span className="generic-tooltip zi999">Sort</span>}
            />
        )
    )
}

export default TableComponentSorting
