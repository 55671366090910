import React, { useEffect, useState } from 'react'
import GenericFormHeaderComponent from './GenericFormHeaderComponent'
import GenericFormFooterComponent from './GenericFormFooterComponent'
import GenericConfirmationModal from '../GenericConfiramationModal';
import ParentModal from '../ParentModal';
import GenericFormComponent from './GenericFormComponent';
import { finalSubmitMandatoryValidationsCheck } from '../../helper/helperTableFunctions'
import IconsPath from '../../assets/icons/index'
import LanguageTranslator from '../../locals/LanguageTranslator';

export const GenericFormSpreadTypeComponent = (props) => {

    const { inputDefaultValue = {}, inputSections = {} } = props;
    // The input data can be used from this component or it can be replaced by the parent input state in some cases.
    const [inputData, setInputData] = useState(inputDefaultValue || {});
    const [errorData, setErrorData] = useState({});
    const [selectedModal, setSelectedModal] = useState("");
    const [selectedData, setSelectedData] = useState({});
    
    const handleChange = (type, data) => {
        switch (type) {
            case "clearData": {
                if (props.clearInputData) {
                    props.clearInputData();
                } else {
                    if (props.updateInputState) {
                        props.updateInputState(inputDefaultValue);
                        if (props.updateErrorState) {
                            props.updateErrorState({});
                        } else {
                            setErrorData({});
                        }
                    } else {
                        setInputData(inputDefaultValue);
                        if (props.updateErrorState) {
                            props.updateErrorState({});
                        } else {
                            setErrorData({});
                        }
                    }
                }
                setSelectedModal(false)
                break;
            }
            case "finalSubmit": {
                // check for all the validations
                let inputState = props.inputState || inputData;
                let errors = {};
                Object.keys(inputSections)?.map(inputKey => {
                    let data = inputSections?.[inputKey];
                    let inputFields = data?.inputFields();
                    let errorMessages = {};
                    if (inputFields?.length > 0) {
                        errorMessages = finalSubmitMandatoryValidationsCheck(inputFields, inputState?.[inputKey], "label");
                    }

                    if (Object.keys(errorMessages || {})?.length > 0) {
                        errors[inputKey] = errorMessages;
                    }
                })
                if (props.updateErrorState) {
                    props.updateErrorState(errors);
                } else {
                    setErrorData({ ...errors });
                }
                if (!Object.keys(errors || {})?.some(item => Object.keys(item || {})?.length > 0)) {
                    props.finalSubmit(props.inputState || inputData)
                }
                break;
            }
            default: {
                setSelectedModal(type);
                setSelectedData(data);
            }
        }
    }

    const renderFormSections = () => {
        return (
            <div className={`gsf-body ${props?.spreadFormExtraClass}`}>
                {console.log(55555555555555, inputSections)}
                {Object.keys(inputSections || {})?.map(sectionKey => {
                    let inputSection = inputSections?.[sectionKey] || {};
                    return <>
                        {/* Render the input section topExtra component */}
                        {inputSection?.topComponent && (typeof (inputSection?.topComponent) == "function") ? inputSection?.topComponent() : inputSection?.topComponent}

                        {/* Render the input section label  */}
                        {inputSection?.label && <LanguageTranslator tag="h2">{typeof (inputSection?.label) == "function" ? inputSection?.label() : inputSection?.label}</LanguageTranslator>}

                        {/* Render the input field sections for the input sections */}
                        <GenericFormComponent
                            rowInput={{
                                rowType: props.rowType || "singleRow",
                                availableInputs: inputSection?.inputFields?.() || [],
                                inputState: props.inputState ? { ...props.inputState?.[sectionKey] } : { ...inputData?.[sectionKey] },
                                inputStateChangeFunction: (data) => { props.updateInputState ? props.updateInputState({ ...props.inputState, [sectionKey]: data }) : setInputData({ ...inputData, [sectionKey]: data }) },
                                errorData: props.errorState ? { ...props?.errorState?.[sectionKey] } : { ...errorData?.[sectionKey] },
                                updateErrorData: (data) => { props.updateErrorState ? props.updateErrorState({ ...props.errorState, [sectionKey]: data }) : setErrorData({ ...errorData, [sectionKey]: data }) }
                            }}
                        />

                        {/* Render the input section bottom extra component */}
                        {inputSection?.bottomComponent && (typeof (inputSection?.bottomComponent) == "function") ? inputSection?.bottomComponent() : inputSection?.bottomComponent}
                    </>
                })}
            </div>
        )
    }

    const getParentChildProps = () => {
        let payload = {
            closeModal: () => setSelectedModal(""),
            icon: () => { return <IconsPath.ConfirmationModalImg /> },
            animationType: "center",
            postitionProps: { top: "32%", left: "25%", margin: 0, position: "fixed", borderRadius: 4 },
            dimensions: { width: "50vw", height: "36vh" },
        }
        switch (selectedModal) {
            case "clearDataModal": {
                return {
                    ...payload,
                    labelComponent: props.clearButtonConfig?.["confirmationMessage"] || "Are you sure you want to clear the selected data ?",
                    buttons: [
                        { label: 'Yes, Proceed', onClick: () => handleChange("clearData"), type: "primary"},
                        { label: 'No, keep it', type: "default", onClick: () => setSelectedModal(false), className: "no-wait-btn" }
                    ],
                }
            }
            default:
        }
    }

    const getChildComponent = () => {
        switch (selectedModal) {
            case "clearDataModal": return GenericConfirmationModal;
            default: return <React.Fragment></React.Fragment>
        }
    }

    const getGenericFormButtons = () => {
        let buttonList = [];
        if (!props?.clearDataButtonNotRequired) {
            buttonList.push({
                onClick: () => handleChange("clearDataModal",),
                type: "default",
                label: props.clearButtonConfig?.["clearButtonLabel"] || "Clear Data",
            })
        }
        if (props.closeModal) {
            buttonList.push({
                onClick: () => props?.closeModal?.(),
                type: "default",
                label: "Close",
            })
        }
        if (props?.extraButtonWithSubmit) {
            buttonList.push({
                onClick: props?.extraButtonWithSubmit?.onClick,
                type: props?.extraButtonWithSubmit?.type,
                label: props?.extraButtonWithSubmit?.label,
                disable: props?.extraButtonWithSubmit?.disable,
                rightIcon: props?.extraButtonWithSubmit?.rightIcon
            })
        }
        buttonList.push({
            type: "primary",
            disabled: () => {
                const hasErrors = Object.values(props.errorState || errorData || {}).some(item => Object.keys(item || {}).length > 0);
                const finalButtonDisabled = props.finalButtonDisabled ? props.finalButtonDisabled() : false;
                return hasErrors || finalButtonDisabled
            },
            onClick: () => handleChange("finalSubmit"),
            label: props.finalSubmitButton || "Submit",
            id: 'final-submit-button'
        });
        return buttonList;
    }

    return (
        <div className={`generic-spread-form ${props.className} ${props.hideAllButtons && "gsf-hide-footer-btn"}`}>
            {props.headerComponent && <GenericFormHeaderComponent
                {...props.headerComponent}
            />}

            {/* Render the first custom items if required */}
            {props.renderCustomItems?.["0"] && props.renderCustomItems?.["0"]?.()}

            {/* Render the current form based on the sections */}
            {renderFormSections()}

            {/* Either the user passes the buttons or we will render the normal required options */}
            {!props.hideAllButtons && (props.buttons ?
                <GenericFormFooterComponent
                    buttons={[
                        {
                            onClick: () => props?.closeModal?.(),
                            type: "default",
                            label: "Close"
                        },
                        (!props?.clearDataButtonNotRequired && {
                            onClick: () => handleChange("clearDataModal",),
                            type: "default",
                            label: props.clearButtonConfig?.["clearButtonLabel"] || "Clear Data",
                        }),
                        ...props.buttons
                    ]}
                />
                : <GenericFormFooterComponent
                    buttons={getGenericFormButtons()}
                />)
            }

            {selectedModal && <ParentModal
                getParentChildProps={getParentChildProps()}
                childComponent={getChildComponent()}
                closeModal={() => { setSelectedModal(false) }}
            />}
        </div>
    )
}
