import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorBoundary from './components/helperComponents/ErrorBoundary';
import { Provider } from 'react-redux';
import createStore from './redux/store'
import {CacheChecker} from './helper/components/CacheChecker';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Hide al console.log inside this system
if (sessionStorage?.getItem("SHOW_CONSOLE_MSG") != "TRUE") {
    if (process.env.REACT_APP_SHOW_CONSOLE_LOGS != "TRUE")
        console.log = console.warn = console.error = () => { };
}

const store = createStore();
root.render(
    <Router>
        <CacheChecker />
        <ErrorBoundary>
            <Provider store={store}>
                <App />
            </Provider>
        </ErrorBoundary>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
