import React, { useEffect, useState } from 'react'
import GenericWizardComponent from '../../genericComponents/GenericWizardComponent';
import GenericFormFooterComponent from '../../genericComponents/forms/GenericFormFooterComponent';
import NoDataFound from '../../genericComponents/NoDataFound';
import GenericFormComponent from '../../genericComponents/forms/GenericFormComponent';
import { dispatchHandler } from '../../helper/apiHelperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import UploadExcelSkeletons from './UploadExcelSkeletons';
import GenericCustomButton from '../../genericComponents/GenericCustomButton';
import { finalSubmitMandatoryValidationsCheck } from '../../helper/helperTableFunctions';
import '../../styles/stylesheets/home/Header.scss'
import Icons from '../../assets/icons';
import SpreadsheetComponent from 'react-spreadsheet-component'
import readXlsxFile from 'read-excel-file'
import IconsPath from '../../assets/icons'
import ParentModal from '../../genericComponents/ParentModal.jsx';
import './UploadExcel.scss'
import GenericConfirmationModal from '../../genericComponents/GenericConfiramationModal.jsx';
import UploadExcelInfo from './UploadExcelInfo.jsx';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';
import GenericInput from '../../genericComponents/input/GenericInputComponent.jsx';
import useCustomToast from '../../hooks/useCustomToast.js';
import LanguageTranslator from '../../locals/LanguageTranslator.js';
import { parseJwt } from '../../helper/genericFunction.js';
import { v4 as uuidv4 } from 'uuid';
let Dispatcher = require('../../../node_modules/react-spreadsheet-component/lib/dispatcher.js');

const UploadExcel = (props) => {
    const [isFromProc, setIsFromProc] = useState(props?.isFromProc || false);

    const [currentStep, setCurrentStep] = useState(0);
    // to store the data of the Generic Form Component
    const [inputState, updateInputState] = useState([]);
    const [errorData, setErrorData] = useState([]);
    // State: To store the selected excel file by the user
    const [currentExcelFile, setCurrentExcelFile] = useState(null);
    // State: To store the updated Excel sheet with applied mapping of data.
    const [updatedCurrentExcelFile, setUpdatedCurrentExcelFile] = useState(null);
    // To store the template types list
    const [excelTypeList, setExcelTypeList] = useState([]);
    // Store the loading of the next button when current step is 0.
    const [buttonLoading, setButtonLoading] = useState(false);
    // State: To store the response from the upload file api.
    const [uploadFileResponse, setUploadFileResponse] = useState(null);
    // State: To check if the user has clicked submit or not
    const [submitLoading, setSubmitLoading] = useState(false);
    // State: To store the response of the api from the validation data.
    const [validateDataResponse, setValidateDataResponse] = useState({});
    // State: To store the case if the user has validated data and has not made any changes;
    const [isValidateDisabled, setIsValidateDisabled] = useState(false);
    // State: To store if the data is validated or not
    const [isCurrentDataValidated, setIsCurrentDataValidated] = useState(false);

    const customToast = useCustomToast();

    // States to be used in case of procurement upload excel
    const [updatedProcExcelData, setUpdatedProcExcelData] = useState({});
    // Will have data in format of = {
    //     "breakpoint" : [
    //         "data of breakpoint"
    //     ]
    // }

    // State: To store the current expanded data inside this state'
    const [currentExpandedBreakpoint, setCurrentExpandedBreakpoint] = useState("");

    // To hadnle the copy functionality inside the summary page
    const [copied, setCopied] = useState(false);
    // State : To store the config keys response
    const [configKeys, setConfigKeys] = useState({});

    const getSystemConfigKeysData = useSelector(state => state?.digivendStore?.getSystemConfigKeysData);

    const handleCopy = (textCopy) => {
        navigator.clipboard.writeText(textCopy);
        setCopied(true)
    }

    useEffect(() => {
        if (copied)
            setTimeout(() => {
                setCopied(false);
            }, 3000)
    }, [copied])
    const history = useHistory();
    const [selectedModal, setSelectedModal] = useState(false);
    const generalStore = useSelector(state => state.generalStore)
    const masterDbStore = useSelector(state => state.masterDbStore);
    const digiArsStore = useSelector(state => state.digiArsStore)
    const dispatch = useDispatch()

    useEffect(() => {
        // Call for the template api.
        if (isFromProc) {
            dispatchHandler(dispatch, "getProcExcelTemplateRequest", {
                dropDownKey: "DIGIPROC_EXCEL_HEADER"
            });
        } else {
            dispatchHandler(dispatch, "getGenericExcelTemplateRequest");
        }

        // Call for the config keys api.
        dispatchHandler(dispatch, "getSystemConfigKeysRequest")

        Dispatcher.subscribe('dataChanged', (data) => {
            if (currentStep === 3) {
                if (isFromProc) {
                    let updatedData = { ...updatedProcExcelData };
                    updatedData[currentExpandedBreakpoint] = data?.rows;
                    setUpdatedProcExcelData(updatedData);
                } else {
                    setUpdatedCurrentExcelFile(data.rows);
                }
            } else
                setCurrentExcelFile(data.rows);
            setIsValidateDisabled(false);
            setIsCurrentDataValidated(false);
        }, "spreadsheet-1")


    }, [])

    useEffect(()=>{
        if(getSystemConfigKeysData?.isSuccess){
            setConfigKeys({
                // Backup limit is going to be 1000.
                "EXCEL_UPLOAD_ROWS_LIMIT" : getSystemConfigKeysData?.data?.resource?.["EXCEL_UPLOAD_ROWS_LIMIT"] || 1000,
            })
        }
    },[getSystemConfigKeysData?.isSuccess])

    useEffect(() => {
        if (generalStore?.["getGenericExcelTemplateData"]?.isSuccess) {
            let data = generalStore?.["getGenericExcelTemplateData"]?.data?.resource
            setExcelTypeList(data || []);
            if (data?.[0]) {
                updateInputState(prev => {
                    prev[0] = {
                        "value": data?.[0]
                    }
                    return prev;
                })
            }
        }
    }, [generalStore?.["getGenericExcelTemplateData"]?.isSuccess]);

    useEffect(() => {
        if (digiArsStore?.downloadTemplateData?.isSuccess) {
            window?.open(digiArsStore?.downloadTemplateData?.data?.resource || "");
        }
    }, [digiArsStore?.downloadTemplateData?.isSuccess])

    // In case of proc set the template according to this
    useEffect(() => {
        if (generalStore?.["getProcExcelTemplateData"]?.isSuccess) {
            let data = generalStore?.["getProcExcelTemplateData"]?.data?.resource || {};
            let updatedExcelList = [];

            let indentTemplate = {
                excelHeader: data?.["excelHeader"] || {},
                value: "Purchase Indent",
                key: "PURCHASE_INDENT",
                mandateHeader: data?.["mandateHeader"] || {},
            }

            updatedExcelList.push(indentTemplate)

            updatedExcelList.push({
                excelHeader: data?.["excelHeader"] || {},
                value: "Purchase Order",
                key: "PURCHASE_ORDER",
                mandateHeader: data?.["mandateHeader"] || {},
            })
            setExcelTypeList(updatedExcelList);

            if (isFromProc) {
                updateInputState(prev => {
                    prev[0] = {
                        "value": indentTemplate
                    }
                    return prev;
                })
            }
        }
    }, [generalStore?.["getProcExcelTemplateData"]?.isSuccess])


    // Handle the response from the upload Excel api
    useEffect(() => {
        let parseToken = parseJwt(sessionStorage.getItem('token'));
        if (masterDbStore?.["uploadMasterDataImagesData"]?.isSuccess) {
            setUploadFileResponse(masterDbStore?.["uploadMasterDataImagesData"]?.data?.resource || null);
            setButtonLoading(true);
            // Get the data from the file and set it to the currentExcel file
            readXlsxFile(inputState?.[0]?.["uploadFile"]?.[0])?.then(rows => {
                let deleteRowOnTempletes = ["PRODUCTS_CATALOGUE_UPLOAD_FOOTWEAR", "PRODUCTS_CATALOGUE_UPLOAD_ACCESSORIES", "PRODUCTS_CATALOGUE_UPLOAD_APPREALS"], deleteRowOnTheseIds = ["8534", "754"];
                if(deleteRowOnTheseIds?.includes(parseToken?.schemaEntID) && deleteRowOnTempletes.includes(inputState?.[0]?.value?.key)) {
                    let indicesToRemove = [0, 2, 3];
                    rows = rows?.filter((_, index) => !indicesToRemove?.includes(index));
                    rows = rows?.map(row => row.slice(1));
                }
                rows = validateData(rows);
                let rowsLimit = Number(configKeys?.["EXCEL_UPLOAD_ROWS_LIMIT"] || "1000");
                if(rows?.length <= rowsLimit ){
                    setCurrentExcelFile(rows || []);
                    setCurrentStep(1);
                }else{
                    customToast({
                        "title" : "Rows Validation Error!",
                        "description" : `Uploaded excel number of rows should not exceed ${rowsLimit}!`,
                        "status" : "error",
                    })
                }

                setButtonLoading(false)
            })
        }
    }, [masterDbStore?.["uploadMasterDataImagesData"]?.isSuccess])

    // Handle the response of the validate file api
    useEffect(() => {
        if (generalStore?.["validateGenericExcelSheetData"]?.isSuccess) {
            // If user has submitted the data.
            if (submitLoading) {
                setSubmitLoading(false);
                let updatedInputData = { ...inputState };
                updatedInputData[4] = generalStore?.["validateGenericExcelSheetData"]?.data?.resource || {};
                updateInputState({ ...updatedInputData });
                setCurrentStep(4);
            } else {
                let apiErrors = generalStore?.["validateGenericExcelSheetData"]?.data?.resource?.sheet1 || {};
                setValidateDataResponse(apiErrors);
                setIsValidateDisabled(true);
                if (apiErrors?.["validation"] === "success") {
                    setIsCurrentDataValidated(true);
                }
            }
            sessionStorage.removeItem("X-REQUEST-ID")
        }
    }, [generalStore?.["validateGenericExcelSheetData"]?.isSuccess])

    // Handle the response of the submit api of the procurement excel upload
    useEffect(() => {
        if (generalStore?.["submitProcExcelData"]?.isSuccess) {
            let updatedInputData = { ...inputState };
            updatedInputData[4] = {
                "refNo": generalStore?.["submitProcExcelData"]?.data?.message?.split(':')?.[1]
            };
            setButtonLoading(false)
            updateInputState({ ...updatedInputData });
            setCurrentStep(4);
            setSubmitLoading(false);
        }
        if(generalStore?.["submitProcExcelData"]?.isError) {
            setButtonLoading(false)
        }
    }, [generalStore?.["submitProcExcelData"]?.isSuccess, generalStore?.["submitProcExcelData"]?.isError])

    // Handle the response of the validated excel file in case of proc
    useEffect(() => {
        if (generalStore?.["validateProcExcelData"]?.isSuccess) {
            if (submitLoading) {
                setSubmitLoading(false);
                let updatedInputData = { ...inputState };
                updatedInputData[4] = generalStore?.["validateProcExcelData"]?.data?.resource;
                updateInputState({ ...updatedInputData });
                setCurrentStep(4);
            } else {
                let apiErrors = generalStore?.["validateProcExcelData"]?.data?.resource || {};
                setValidateDataResponse(apiErrors);
                setIsValidateDisabled(true);
                if (Object.values(apiErrors || {})?.every(item => item?.["validation"] === "success")) {
                    setIsCurrentDataValidated(true);
                }
            }
        }
    }, [generalStore?.["validateProcExcelData"]?.isSuccess])

    const resetAllStates = () => {
        setCurrentStep(0);
        updateInputState([]);
        setErrorData([]);
        setCurrentExcelFile(null);
        setUpdatedCurrentExcelFile(null);
        setUpdatedProcExcelData(null);
        setUploadFileResponse(null);
        setValidateDataResponse({});
        setIsValidateDisabled(false);
        setIsCurrentDataValidated(false);
    }

    // Function : to check if the date format is validated or not
    function isValidDateFormat(dateString) {
        const format = 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (India Standard Time)';
        return moment(dateString, format, true).isValid();
    }

    // Function to convert Excel serial number to date string (YYYY-MM-DD format)
    const excelSerialToDateString = (serial) => {
        const MS_PER_DAY = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
        const excelEpoch = new Date('1899-12-30'); // Excel's epoch date (one day before the JavaScript epoch)
        
        // Calculate the date by adding the number of days (given by the serial number) to the Excel epoch
        const date = new Date(excelEpoch.getTime() + serial * MS_PER_DAY);

        // Format the date as YYYY-MM-DD using JavaScript date methods
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Month is zero-based
        const day = ('0' + date.getDate()).slice(-2);

        return `${year}-${month}-${day}`;
    }
    // Function -> To validate the uploaded excel file by the user.
    const validateData = (data) => {
        let temp = [];
        data?.map(function (value, index, arr) {
            let obj = [];
            // Removing the null rows from the excel
            if (!value?.every(item => item == null || item == "")) {
                value?.map((val, innerIndex) => {
                    // Check if the provided value is date if date then convert it into string.
                    if (isValidDateFormat(val)) {
                        obj.push(moment(val)?.format("YYYY-MM-DD"));
                    }
                    else if(index !== 0 && ["valid from", "valid to", "order date", "delivery date"]?.includes(data?.[0]?.[innerIndex]?.toLowerCase()) && typeof(val) == "number") {
                        obj.push(excelSerialToDateString(val));
                    }
                    else {
                        obj.push(val);
                    }
                })
                temp.push(obj);
            }
            return null;
        })
        return temp;
    }

    // Function -> To provide the initial mapped values for Map Column Step
    const provideMappedColumns = () => {
        let appliedExcelHeaders = currentExcelFile?.[0] || [];
        let headers = inputState?.[0]?.["value"]?.["excelHeader"] || {};
        let mappedColumns = {};
        Object.keys(headers || {})?.map(headerKey => {
            // If the heder is availble inside the provided excel headers
            if (appliedExcelHeaders?.includes(headers?.[headerKey]))
                mappedColumns[headerKey] = { [headerKey]: headers?.[headerKey] }
        })
        return mappedColumns;
    }

    // Function -> To handle the step changes.
    const switchStep = (stepType) => {
        switch (currentStep) {
            case 0: {
                if (stepType == "back") {
                    setSelectedModal("backConfirmationModal");
                } else {
                    let inputFields = getInputFields();
                    let errorMessages = finalSubmitMandatoryValidationsCheck(inputFields, inputState?.[0], "label");
                    if (Object.keys(errorMessages || {})?.length > 0) {
                        let updatedErrorData = [...errorData];
                        updatedErrorData[0] = errorMessages;
                        setErrorData([...updatedErrorData]);
                    } else {
                        if (inputState?.[0]?.["uploadFile"]?.[0]) {
                            let formData = new FormData();
                            formData.append("file", inputState?.[0]?.["uploadFile"]?.[0]);
                            formData.append("filePath", "");
                            formData.append("removeFileName", "");
                            formData.append("copyFileName", "");
                            dispatchHandler(dispatch, "uploadMasterDataImagesRequest", formData);
                        }
                    }
                }
                break;
            }
            case 1: {
                if (stepType === "back") {
                    setCurrentStep(0);
                    setCurrentExcelFile(null);
                } else {
                    let updatedInputData = [...inputState];
                    updatedInputData[2] = provideMappedColumns() || {};
                    updateInputState([...updatedInputData]);
                    setCurrentStep(2);
                }
                break;
            }
            case 2: {
                if (stepType === "back") {
                    setCurrentStep(1);
                    let updatedInputData = [...inputState];
                    updatedInputData[2] = {};
                    updateInputState([...updatedInputData]);
                } else {
                    let inputFields = getInputFields();
                    let errorMessages = finalSubmitMandatoryValidationsCheck(inputFields, inputState?.[2], "label");
                    if (Object.keys(errorMessages || {})?.length > 0) {
                        let updatedErrorData = [...errorData];
                        updatedErrorData[2] = errorMessages;
                        setErrorData([...updatedErrorData]);
                    } else {
                        handleChange("adjustExcelData")
                        setCurrentStep(3);
                    }
                }
                break;
            }
            case 3: {
                if (stepType == "back")
                    setSelectedModal("confirmationModal");
                else {
                    setSubmitLoading(true);
                    handleChange("validateFile", true);
                }
                break;
            }
            case 4: {
                resetAllStates();
                break;
            }
            default: {

            }
        }
    }

    const getInputFields = () => {
        switch (currentStep) {
            case 0: {
                return [
                    // Choose Type Input Field.
                    {
                        key: "value", type: "dropdown", label: "Choose Template", mandatory: true, genInputExtraClass: "gfb-choose", iconRight: <IconsPath.DownArrowNew />, inputProps: () => ({
                            headerKeys: { "value": {} },
                            itemKeys: { "value": ["value"] },
                            getListData: () => (excelTypeList || [])
                        }),
                        inputComponents: {
                            "bottom": () => {
                                return inputState?.[0]?.["value"]?.["value"] && <GenericCustomButton
                                    leftIcon={<Icons.DownloadExcelTemplateGreenIcon />}
                                    label="Download Template"
                                    onClick={() => handleChange("downloadTemplate", inputState?.[0]?.["value"])}
                                />
                            }
                        }
                    },
                    // Upload File input field.
                    {
                        key: "uploadFile", mandatory: true, type: "upload", genInputExtraClass: "gfb-upload", inputProps: () => ({
                            acceptFiles: ".xlsx,.xls,.csv",
                            maxSize: 200,
                            renderCustomPlaceholder: () => {
                                return (
                                    <div className='gibu-inner'>
                                        <div><IconsPath.UploadIcon /></div>
                                        <LanguageTranslator tag="h4">Drag & Drop files here</LanguageTranslator>
                                        <LanguageTranslator tag="p">Files supported : XLSX, XLS, CSV</LanguageTranslator>
                                        <LanguageTranslator tag="span" className='generic-approve-btn'>Choose File</LanguageTranslator>
                                        <LanguageTranslator tag="p">Maximum Size : Upto 10 MB</LanguageTranslator>
                                    </div>
                                )
                            }
                        })
                    }
                ]
            }
            case 2: {
                let headers = inputState?.[0]?.["value"]?.["excelHeader"] || {};
                let mandateHeaders = inputState?.[0]?.["value"]?.["mandateHeader"] || {};
                let inputFields = Object.keys(headers)?.map(key => ({
                    key: key, type: "dropdown", iconRight: <Icons.DownArrowNew />, label: headers?.[key], mandatory: mandateHeaders?.[key] || false, inputProps: () => ({
                        search: {
                            placeholder: "Type To Search",
                            customSearchFunction: (listState) => {
                                const { listData, currentSearch } = listState;
                                return listData?.filter(item => item?.[key].toLowerCase().includes(currentSearch?.toLowerCase()));
                            }
                        },
                        headerKeys: { [key]: {} },
                        itemKeys: { [key]: [key] },
                        getListData: () => currentExcelFile?.[0]?.map(item => ({ [key]: item })) || [],
                    })
                }))
                return inputFields;
            }
            default:
        }
    }

    // Function to check if the next button is disabled or not
    const isNextDisabled = () => {
        let errorCondition = Object.keys(errorData?.[currentStep] || {})?.length > 0;
        switch (currentStep) {
            case 0: {
                return errorCondition || generalStore?.["getGenericExcelTemplateData"]?.isLoading
            }
            case 1: return currentExcelFile?.length <= 1;
            case 2: return errorCondition;
            case 3: return !isCurrentDataValidated;
            default: {
                return errorCondition;
            }
        }
    }

    const handleChange = (type, data) => {
        console.log(4444444444444, type, data, submitLoading)
        switch (type) {
            case "downloadTemplate": {
                if (isFromProc) {
                    dispatchHandler(dispatch, "downloadTemplateRequest", {
                        "templateType": "DIGIPROC_EXCEL_UPLOAD",
                    })
                } else {
                    data?.template && window?.open(data?.template)
                }
                break;
            }
            // Validate excel file at current step is 3
            case "validateFile": {
                let tokenData = parseJwt(sessionStorage?.getItem("token"));
                sessionStorage.setItem("X-REQUEST-ID", uuidv4() + (tokenData.jti || ""));
                let currentHeaders = inputState?.[0]?.["value"]?.["excelHeader"] || {};
                let excelData;
                // Create the excel files upload 

                // Function to get the payload data
                const getPayloadData = (data, excelHeaders) => {
                    let values = [];
                    data?.map((row, i) => {
                        let obj = {};
                        if (i !== 0) {
                            Object.keys(currentHeaders || {})?.map(headerKey => {
                                // Get the index of current headers in excel headers;
                                let index = excelHeaders?.findIndex(item => item === currentHeaders?.[headerKey]);
                                obj[headerKey] = index !== -1 ? row?.[index] || "" : ""
                            })
                            values.push(obj);
                        }
                    })
                    return values;
                }

                if (isFromProc) {
                    excelData = {};
                    Object.keys(updatedProcExcelData || {})?.map(breakpoint => {
                        excelData[breakpoint] = getPayloadData(updatedProcExcelData?.[breakpoint] || [], updatedProcExcelData?.[breakpoint]?.[0]);
                    })
                } else {
                    excelData = getPayloadData(updatedCurrentExcelFile, updatedCurrentExcelFile?.[0]);
                }

                let payload = isFromProc ? data ? {
                    additionalData: validateDataResponse || {},
                    data: excelData || {},
                    filePath: uploadFileResponse?.[0]?.filePath,
                    isPO: inputState?.[0]?.["value"]?.["key"] == "PURCHASE_ORDER" ? true : false,
                    totalItemCount: Object?.values(validateDataResponse)?.reduce((a, b) => a + Number(b.totalQty || ""), 0) || "",
                    totalPOCount: Object.keys(updatedProcExcelData || {})?.length || "",
                    totalAmount: Object?.values(validateDataResponse)?.reduce((a, b) => a + Number(b.totalNetAmount || ""), 0) || ""
                } : {
                    isPO: inputState?.[0]?.["value"]?.["key"] == "PURCHASE_ORDER" ? true : false,
                    data: excelData
                } : {
                    data: { "sheet1": excelData },
                    fileName: uploadFileResponse?.[0]?.fileName,
                    filePath: uploadFileResponse?.[0]?.filePath,
                    isSubmitExcel: data,
                    uploadType: inputState?.[0]?.["value"]?.key,
                    truncateReq: inputState?.[0]?.["value"]?.turncateReq || inputState?.[0]?.["value"]?.turncateReq === 0 ? inputState?.[0]?.["value"]?.turncateReq : ""
                }
                if(isFromProc && data) {
                    setButtonLoading(true)
                }
                dispatchHandler(dispatch, isFromProc ? data ? "submitProcExcelRequest" : "validateProcExcelRequest" : "validateGenericExcelSheetRequest", payload);
                break;
            }
            // Map the data according to the mapping created by user.
            case "adjustExcelData": {
                let file = JSON.parse(JSON.stringify(currentExcelFile));
                let headers = inputState?.[2];
                let excelHeaders = currentExcelFile?.[0];
                let updatedCurrentExcelFile = [];
                let currentHeaders = inputState?.[0]?.["value"]?.["excelHeader"] || {} || {}
                // Create the excel files upload 

                // In case when the user is using this for procurement.
                if (isFromProc) {
                    let updatedData = {};
                    let breakpointData = ["NA"];
                    let breakpointKey = inputState?.[2]?.["docno"]?.["docno"];
                    let breakpointIndex;
                    if (breakpointKey) {
                        // The index on which the header is set;
                        breakpointIndex = file?.[0]?.findIndex(header => header === breakpointKey);
                        let data = [];
                        file?.slice(1)?.map((row, i) => {
                            if (!data?.includes(row[breakpointIndex] || "NA")) {
                                data.push(row[breakpointIndex] || "NA")
                            }
                        })
                        breakpointData = data || [];
                    }


                    breakpointData?.map((key) => {
                        if (!updatedData?.[key]) {
                            updatedData[key] = [];
                        }
                        file?.map((row, i) => {
                            if (!updatedData?.[key]?.[i]) {
                                updatedData[key][i] = [];
                            }
                            if (i !== 0) {
                                // Check if the choosen breakpoint is present inside the current row data;
                                if (inputState?.[2]?.["docno"]?.["docno"] ? (row[breakpointIndex] ?? "") == (key == "NA" ? "" : key) : true) {
                                    // Mapping the current headers
                                    Object.keys(currentHeaders || {})?.map((current, currentIndex) => {
                                        let index = excelHeaders?.findIndex(item => item === headers?.[current]?.[current]);
                                        if (index !== -1)
                                            updatedData[key][i][currentIndex] = row[index];
                                        else
                                            updatedData[key][i][currentIndex] = "";
                                    })
                                }
                            } else {
                                updatedData[key][0] = Object.values(currentHeaders) || []
                            }
                        })
                        updatedData[key] = updatedData?.[key]?.filter(item => item?.length > 0);
                    })

                    setUpdatedProcExcelData(updatedData);
                } else {
                    file?.map((row, i) => {
                        if (!updatedCurrentExcelFile?.[i]) {
                            updatedCurrentExcelFile[i] = [];
                        }
                        if (i !== 0) {
                            Object.keys(currentHeaders)?.map((current, currentIndex) => {
                                let index = excelHeaders?.findIndex(item => item === headers?.[current]?.[current]);
                                if (index !== -1)
                                    updatedCurrentExcelFile[i][currentIndex] = row[index];
                                else
                                    updatedCurrentExcelFile[i][currentIndex] = "";
                            })
                        } else {
                            updatedCurrentExcelFile[0] = Object.values(currentHeaders) || []
                        }
                    })
                    setUpdatedCurrentExcelFile(updatedCurrentExcelFile);
                }

                break;
            }
            case "viewExcelUploadedPages": {
                // Navigate to the page for which we selected the excel upload
                history.push(inputState?.[0]?.["value"]?.pageLink?.replace('#', ''));
                break;
            }
            case "viewExcelLogs": {
                history.push("/excel-upload-logs")
                break;
            }
            default:
        }
    }

    const renderWizardComponent = () => {
        return <div className='ue-sec'>
            <GenericWizardComponent
                currentStep={currentStep}
                wizardSteps={{
                    "chooseFile": {
                        label: "Choose File",
                    },
                    "dataPreview": {
                        label: "Data Preview",
                    },
                    "mapColumn": {
                        label: "Map Column",
                    },
                    "dataValidation": {
                        label: "Data Validation"
                    },
                    "summary": {
                        label: "Summary"
                    }
                }}
            />
        </div>
    }

    // Function: To render the header component 
    const renderHeaderComponent = () => {
        switch (currentStep) {
            case 4:
            case 3: {
                return <React.Fragment>
                    <div className='flex items-center justify-between gap-2'>
                        <LanguageTranslator tag="h1">Summary</LanguageTranslator>
                        <div className='uploadExcelSummarySection whitespace-nowrap'>
                            <GenericFormFooterComponent
                                buttons={[
                                    {
                                        type: "default",
                                        onClick: () => handleChange("viewExcelLogs"),
                                        label: 'View Excel Logs'
                                    },
                                    currentStep !== 4 && {
                                        type: "default",
                                        onClick: () => switchStep("back"),
                                        label: 'Back'
                                    },
                                    currentStep === 3 && {
                                        type: "primary",
                                        label: "Validate Excel",
                                        disabled: () => isValidateDisabled,
                                        onClick: () => handleChange("validateFile", false)
                                    },
                                    currentStep == 4 && isFromProc && {
                                        type: "primary",
                                        label: inputState?.[0]?.["value"]?.["key"] == "PURCHASE_ORDER" ? "View Orders" : "View Indents",
                                        onClick: () => history?.replace(inputState?.[0]?.["value"]?.["key"] == "PURCHASE_ORDER" ? '/purchase/purchaseOrderHistory' : '/purchase/purchaseIndentHistory')
                                    },
                                    {
                                        type: "primary",
                                        label: currentStep == 4 ? "Upload Another Excel" : "Next",
                                        loading: buttonLoading,
                                        onClick: () => switchStep("next"),
                                        disabled: () => isNextDisabled()
                                    },
                                ].filter(Boolean)}
                            />
                            </div>
                    </div>
                    <div className='ueh-sec'>
                        <div className='ueh-left'>
                            {/* Render the summary in case of procurement */}
                            <div className='uehl'>
                                <LanguageTranslator tag="h5">Total Items</LanguageTranslator>
                                <LanguageTranslator tag="h3">{currentExcelFile?.length - 1}</LanguageTranslator>
                            </div>
                            {isFromProc && <div className='uehl'>
                                {currentExcelFile?.key == "PURCAHSE_INDENT" ? <h5>Total PI</h5> : <LanguageTranslator tag='h5'>Total PO</LanguageTranslator>}
                                <h3>{Object.keys(updatedProcExcelData || {})?.length}</h3>
                            </div>}
                            <div className='uehl'>
                                <LanguageTranslator tag="h5">Total Quantity</LanguageTranslator>
                                <LanguageTranslator tag="h3">{Object?.values(validateDataResponse)?.reduce((a, b) => a + Number(b.totalQty || ""), 0)}</LanguageTranslator>
                            </div>
                            <div className='uehl'>
                                <LanguageTranslator tag="h5">Total Amount</LanguageTranslator>
                                <LanguageTranslator tag="h3"><LanguageTranslator tag="span" className="repee">&#x20B9; </LanguageTranslator> {Object?.values(validateDataResponse)?.reduce((a, b) => a + Number(b.totalNetAmount || ""), 0)}</LanguageTranslator>
                            </div>
                        </div>
                        <div className='ueh-right'>
                            {validateDataResponse?.["msg"] && <h4 style={{ color: validateDataResponse?.["validation"] == "failed" ? "red" : "green" }}>{validateDataResponse?.["msg"]}</h4>}
                            <UploadExcelInfo />
                            <GenericInput
                                value="Search Item"
                                iconLeft={<Icons.SearchIcon />}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                </React.Fragment>
            }
            default: {
                return <React.Fragment>
                    <div className='flex items-center gap-2 justify-between'>
                            <LanguageTranslator tag="h1">Upload Excel Wizard</LanguageTranslator>
                            <GenericFormFooterComponent
                                buttons={[
                                    {
                                        type: "default",
                                        onClick: () => handleChange("viewExcelLogs"),
                                        label: 'View Excel Logs'
                                    },
                                    currentStep !== 4 && {
                                        type: "default",
                                        onClick: () => switchStep("back"),
                                        label: 'Back'
                                    },
                                    currentStep === 3 && {
                                        type: "primary",
                                        label: "Validate Excel",
                                        disabled: () => isValidateDisabled,
                                        onClick: () => handleChange("validateFile", false)
                                    },
                                    currentStep == 4 && isFromProc && {
                                        type: "primary",
                                        label: inputState?.[0]?.["value"]?.["key"] == "PURCHASE_ORDER" ? "View Orders" : "View Indents",
                                        onClick: () => history?.replace(inputState?.[0]?.["value"]?.["key"] == "PURCHASE_ORDER" ? '/purchase/purchaseOrderHistory' : '/purchase/purchaseIndentHistory')
                                    },
                                    {
                                        type: "primary",
                                        label: currentStep == 4 ? "Upload Another Excel" : "Next",
                                        loading: buttonLoading,
                                        onClick: () => switchStep("next"),
                                        disabled: () => isNextDisabled()
                                    },
                                ].filter(Boolean)}
                            />
                        </div>
                    <LanguageTranslator tag="h4">You can upload only in .xlsx, .xls or .csv formats and file size should not exceed 10MB</LanguageTranslator>
                </React.Fragment>
            }
        }
    }

    // Function : TO provide the updated cell classes for the data inside the excel.
    // const provideUpdatedCellClasses = (excelData, validationResponse) => {
    //     let rows = [];
    //     excelData?.map((row, index) => {
    //         let headers = excelData?.[0];
    //         if (!rows[index]) {
    //             rows[index] = [];
    //         }
    //         if (index != 0) {
    //             headers?.map((headerKey, headerIndex) => {
    //                 let code = provideExcelHeaderCode(headerIndex + 1, index);
    //                 if (validationResponse?.["address"]?.["dataIssue"]?.includes(code)) {
    //                     rows[index][headerIndex] = "red";
    //                 } else if (validationResponse?.["address"]?.["mandate"]?.includes(code)) {
    //                     rows[index][headerIndex] = "maroon";
    //                 } else if (validationResponse?.["address"]?.["mismatch"]?.includes(code)) {
    //                     rows[index][headerIndex] = "orange";
    //                 } else if (validationResponse?.["address"]?.["ext"]?.includes(code)) {
    //                     rows[index][headerIndex] = "yellow"
    //                 } else {
    //                     rows[index][headerIndex] = '';
    //                 }
    //             })
    //         } else {
    //             headers?.map(key => rows[index].push(''));
    //         }
    //     });

    //     return rows;
    // }

    const provideUpdatedCellClasses = (excelData, validationResponse, rowOffset = 0) => {
        let rows = [];
        // Convert the comma-separated addresses to arrays for easier lookup
        const validationAddresses = validationResponse?.address || {};
        const dataIssueAddresses = new Set(validationAddresses.dataIssue?.split(',') || []);
        const mandateAddresses = new Set(validationAddresses.mandate?.split(',') || []);
        const mismatchAddresses = new Set(validationAddresses.mismatch?.split(',') || []);
        const extAddresses = new Set(validationAddresses.ext?.split(',') || []);
        
        excelData?.forEach((row, rowIndex) => {
            let headers = excelData?.[0];

            let adjustedRowIndex = rowIndex + rowOffset;
            
            if (!rows[rowIndex]) {
                rows[rowIndex] = [];
            }
            
            if (rowIndex !== 0) {
                headers?.forEach((headerKey, headerIndex) => {
                    let code = provideExcelHeaderCode(headerIndex + 1, adjustedRowIndex);
                    
                    // We are working with exact matches
                    if (dataIssueAddresses.has(code)) {
                        rows[rowIndex][headerIndex] = "red";
                    } else if (mandateAddresses.has(code)) {
                        rows[rowIndex][headerIndex] = "maroon";
                    } else if (mismatchAddresses.has(code)) {
                        rows[rowIndex][headerIndex] = "orange";
                    } else if (extAddresses.has(code)) {
                        rows[rowIndex][headerIndex] = "yellow";
                    } else {
                        rows[rowIndex][headerIndex] = '';
                    }
                });
            } else {
                headers?.forEach(() => rows[rowIndex].push(''));
            }
        });
    
        return rows;
    }
    

    // Function : To provide the excel headers code from the index of the headers of excel sheet
    const provideExcelHeaderCode = (headerIndex, rowIndex) => {
        var str = '', q, r;
        while (headerIndex > 0) {
            q = (headerIndex - 1) / 26;
            r = (headerIndex - 1) % 26
            headerIndex = Math.floor(q)
            str = String.fromCharCode(65 + r) + str;
        }
        return `${str}${rowIndex}`;
    }

    // Function : To render the spreadsheet on the basis of the breakpoint

    const getPreviousKey = (obj, currentKey) => {
        const keys = Object.keys(obj);
        const currentIndex = keys.indexOf(currentKey);
        
        if (currentIndex > 0) {
            return keys[currentIndex - 1]; // Return the previous key
        } else {
            return null; // Return null if there is no previous key (first key)
        }
    }

    const renderSpreasheetBasedOnBreakpoint = (isFromProc) => {

        if (isFromProc) {
            // Render different section based on the breakpoint data
            let rowOffset = 0;
            return Object.keys(updatedProcExcelData || {})?.map((breakpoint, index) => {
                let validationData = validateDataResponse?.[breakpoint] || {};

                rowOffset += (updatedProcExcelData?.[getPreviousKey(updatedProcExcelData, breakpoint)]?.length - 1) || 0;

                let updatedCellClasses;

                if (currentStep == 3) {
                    updatedCellClasses = provideUpdatedCellClasses(updatedProcExcelData?.[breakpoint], validationData, rowOffset);
                }

                const testVars = {
                    initialData: {
                        rows: updatedProcExcelData?.[breakpoint] || [],
                    },
                    config: {
                        rows: 10,
                        columns: 20,
                        hasHeadColumn: false,
                        isHeadColumnString: false,
                        hasHeadRow: true,
                        isHeadRowString: false,
                        canAddRow: false,
                        canAddColumn: false,
                        emptyValueSymbol: '-',
                        hasLetterNumberHeads: false
                    },
                    cellClasses: {
                        rows: updatedCellClasses || [
                            ['', '', '', '', '', '', '', ''],
                            ['', '', '', '', '', '', '', ''],
                            ['', '', '', '', '', '', '', ''],
                            ['', '', '', '', '', '', '', ''],
                        ]
                    }
                };

                return (
                    <div>
                        <div className='ueu-sec' onClick={() => { setCurrentExpandedBreakpoint(currentExpandedBreakpoint == breakpoint ? "" : breakpoint) }}>
                            <div className='ueus-left '>
                                <div className='ueusl'>
                                    <LanguageTranslator tag="h3">Break Point</LanguageTranslator>
                                    <LanguageTranslator tag="h5">{breakpoint}</LanguageTranslator>
                                </div>
                                <div className='ueusl'>
                                    <LanguageTranslator tag="h3">No. of Lineitems uploaded</LanguageTranslator>
                                    <LanguageTranslator tag="h5">{updatedProcExcelData?.[breakpoint]?.length > 0 ? updatedProcExcelData?.[breakpoint]?.length - 1 : 0}</LanguageTranslator>
                                </div>
                                <div className='ueusl'>
                                    <LanguageTranslator tag="h3">Quantity</LanguageTranslator>
                                    <LanguageTranslator tag="h5">{validationData?.["totalQty"] || "0"}</LanguageTranslator>
                                </div>
                                <div className='ueusl'>
                                    <LanguageTranslator tag="h3">Total Amount</LanguageTranslator>
                                    <LanguageTranslator tag="h5"><LanguageTranslator tag="span" className="repee">&#x20B9; </LanguageTranslator> {validationData?.["totalNetAmount"] || "0"}</LanguageTranslator>
                                </div>

                                {/* Render the validation data */}
                                <div className='ueusl'>
                                    {validationData?.["msg"] ? <LanguageTranslator tag="h4" style={{ color: validationData?.["validation"] == "failed" ? "red" : "green" }}>{validationData?.["msg"]}</LanguageTranslator> : <h4>Not Validated Yet!</h4>}
                                </div>
                            </div>

                            <div className='ueus-right'>
                                <Icons.DownArrowNew className={currentExpandedBreakpoint == breakpoint && "rotate-180"} />
                            </div>
                        </div>
                        <div className='ueu-scroll'>
                            {currentExpandedBreakpoint == breakpoint && (
                                <SpreadsheetComponent
                                    initialData={testVars.initialData}
                                    config={testVars.config}
                                    cellClasses={testVars.cellClasses}
                                    spreadsheetId="spreadsheet-1"
                                />
                            )}
                        </div>
                    </div>
                )
            })
        } else {

            let updatedCellClasses;

            if (currentStep == 3) {
                updatedCellClasses = provideUpdatedCellClasses(updatedCurrentExcelFile, validateDataResponse);
            }

            const testVars = {
                initialData: {
                    rows: currentStep === 3 ? updatedCurrentExcelFile != null ? updatedCurrentExcelFile : [] : currentExcelFile != null ? currentExcelFile : []
                },
                config: {
                    rows: 10,
                    columns: 20,
                    hasHeadColumn: false,
                    isHeadColumnString: false,
                    hasHeadRow: true,
                    isHeadRowString: false,
                    canAddRow: false,
                    canAddColumn: false,
                    emptyValueSymbol: '-',
                    hasLetterNumberHeads: false
                },
                cellClasses: {
                    rows: currentStep == 3 && updatedCellClasses ? updatedCellClasses : [
                        ['', '', '', '', '', '', '', ''],
                        ['', '', '', '', '', '', '', ''],
                        ['', '', '', '', '', '', '', ''],
                        ['', '', '', '', '', '', '', ''],
                    ]
                }
            };
            return <SpreadsheetComponent
                initialData={testVars.initialData}
                config={testVars.config}
                cellClasses={testVars.cellClasses}
                spreadsheetId="spreadsheet-1"
            />
        }
    }

    const renderExcelComponent = () => {
        switch (currentStep) {
            case 0: {
                return renderChooseFileComponent()
            }
            case 1: {
                return <div id="spreadsheet" className="eupbc-table" style={{ width: "100%", height: "60%" }}>
                    {renderSpreasheetBasedOnBreakpoint()}
                </div>
            }
            case 2: {
                return <GenericFormComponent
                    rowInput={{
                        rowType: "singleRow",
                        availableInputs: getInputFields(),
                        inputState: { ...inputState?.[2] || {} },
                        inputStateChangeFunction: (data) => {
                            let updatedInputData = [...inputState];
                            updatedInputData[2] = data;
                            updateInputState(updatedInputData);
                        },
                        errorData: { ...errorData?.[2] || {} },
                        updateErrorData: (data) => {
                            let updatedErrorData = [...errorData];
                            updatedErrorData[2] = data;
                            setErrorData(updatedErrorData);
                        }
                    }}
                />
            }
            case 3: return <div id="spreadsheet" className="eupbc-table">
                {renderSpreasheetBasedOnBreakpoint(isFromProc)}
            </div>;
            case 4: {
                return (
                    <div className='upload-completed'>
                        <div className='upc-head'>
                            <svg className="uploaded-checked-logo" width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.980713 25.1003C0.980713 11.5069 12.0003 0.487305 25.5937 0.487305C39.1872 0.487305 50.2068 11.5069 50.2068 25.1003C50.2068 38.6937 39.1872 49.7134 25.5937 49.7134C12.0003 49.7134 0.980713 38.6937 0.980713 25.1003Z" fill="#03B675" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M37.477 16.2464C38.3508 17.1202 38.3508 18.5369 37.477 19.4107L23.5883 33.9548C22.7145 34.8286 21.2977 34.8286 20.4239 33.9548L13.7113 27.2421C12.8375 26.3683 12.8375 24.9516 13.7113 24.0778C14.5851 23.204 16.0018 23.204 16.8756 24.0778L22.0061 29.2082L34.3126 16.2464C35.1864 15.3725 36.6032 15.3725 37.477 16.2464Z" fill="white" />
                            </svg>
                            <div className='text-area'>
                                <LanguageTranslator tag="p">Upload Completed</LanguageTranslator>
                                <LanguageTranslator tag="h3">{inputState?.[0]?.["value"]?.["value"]}</LanguageTranslator>
                            </div>
                            <div className="upload-items">
                                <LanguageTranslator tag="p">Total Uploaded items</LanguageTranslator>
                                <LanguageTranslator tag="label">{isFromProc ? (Number(Object.keys(updatedProcExcelData || {})?.length)) : (Number(updatedCurrentExcelFile?.length || "") - 1)}</LanguageTranslator>
                            </div>
                            <div className='total-item'>
                                <LanguageTranslator tag="p">Ref Number</LanguageTranslator>
                                <input type="text" autoComplete="off" disabled value={inputState?.[4]?.["refNo"]} id="myInput"></input>
                                {copied ?
                                    <span className='copy-successed'>Copied to Clipboard</span>
                                    :
                                    <button onClick={() => handleCopy(inputState?.[4]?.["refNo"])}>
                                        <Icons.CopyIcon />
                                        <LanguageTranslator tag="span" className="generic-tooltip">Copy UUID</LanguageTranslator>
                                    </button>
                                }

                            </div>
                        </div>
                        {inputState?.[0]?.["value"]?.pageLink && <div className='view-link-btn'>
                            <LanguageTranslator tag="span" className='vbp-btn' onClick={() => handleChange("viewExcelUploadedPages")}>View {inputState?.[0]?.["value"]?.["value"]}</LanguageTranslator>
                        </div>}
                    </div>
                )
            }
            default: {
                <NoDataFound />
            }
        }
    }

    // Render the choose file component when the currentStep value is 0
    const renderChooseFileComponent = () => {
        return generalStore?.["getGenericExcelTemplateData"]?.isLoading ? <UploadExcelSkeletons step={currentStep} /> : <GenericFormComponent
            rowInput={{
                rowType: "spread",
                availableInputs: getInputFields(),
                inputState: { ...inputState?.[0] || {} },
                inputStateChangeFunction: (data) => {
                    let updatedInputData = [...inputState];
                    updatedInputData[0] = data;
                    updateInputState(updatedInputData);
                },
                errorData: { ...errorData?.[0] || {} },
                updateErrorData: (data) => {
                    let updatedErrorData = [...errorData];
                    updatedErrorData[0] = data;
                    setErrorData(updatedErrorData);
                }
            }}
        />
    }

    const getParentChildProps = () => {
        let payload = {
            closeModal: () => { setSelectedModal(false) },
            animationType: "center",
        }

        let confirmationModalPayload = {
            ...payload,
            postitionProps: { top: "32%", left: "32%", position: "fixed", borderRadius: 4 },
            dimensions: { width: "36vw", height: "36vh" },
            icon: () => { return <IconsPath.ConfirmationModalImg /> },
        }

        switch (selectedModal) {
            case "backConfirmationModal": {
                return {
                    ...confirmationModalPayload,
                    labelComponent: "Are you sure you want to go back?, as all the data edited will be lost!",
                    buttons: [
                        {
                            label: "Sure, Yes",
                            className: "generic-approve-btn",
                            onClick: () => {
                                history?.goBack?.()
                            },
                        },
                        {
                            label: "No, wait",
                            className: "no-wait-btn",
                            onClick: () => { setSelectedModal(false) }
                        }
                    ]
                }
            }
            case "confirmationModal": {
                return {
                    ...confirmationModalPayload,
                    labelComponent: "Are you sure you want to go back to Map Column, as all the data edited will be lost!",
                    buttons: [
                        {
                            label: "Sure, Yes",
                            className: "generic-approve-btn",
                            onClick: () => {
                                setCurrentStep(currentStep - 1);
                                setSelectedModal(false);
                                setIsValidateDisabled(false);
                                setIsCurrentDataValidated(false);
                                setValidateDataResponse({})
                                setCurrentExpandedBreakpoint("");
                            },
                        },
                        {
                            label: "No, wait",
                            className: "no-wait-btn",
                            onClick: () => { setSelectedModal(false) }
                        }
                    ]
                }
            }
            default: {
                break;
            }
        }
    }

    const getChildComponent = () => {
        switch (selectedModal) {
            case "backConfirmationModal":
            case "confirmationModal": {
                return GenericConfirmationModal
            }
            default:
        }
    }

    return (
        <div className='upload-excel'>
            {/* Render the wizard component here */}
            {renderWizardComponent()}

            <div className='ue-head'>
                {/* Render the header component of the upload excel */}
                {renderHeaderComponent()}
            </div>

            <div className={`ue-upload ${currentStep === 3 ? 'ue-upload-small-height' : ''}`}>
                {/* Render the main component  */}
                {renderExcelComponent()}
            </div>

            {/* Render the final submit buttons for upload excel */}
            {/* {renderBottomSection()} */}

            {selectedModal && <ParentModal
                getParentChildProps={getParentChildProps()}
                childComponent={getChildComponent()}
                closeModal={() => { setSelectedModal(false) }}
            />}
        </div>
    )
}

export default UploadExcel