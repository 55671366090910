import React, { useEffect, useState } from 'react'
import GenericFormHeaderComponent from '../../../genericComponents/forms/GenericFormHeaderComponent'
import LoginPasswordInput from '../LoginPasswordInput'
import GenericFormFooterComponent from '../../../genericComponents/forms/GenericFormFooterComponent'
import { dispatchHandler } from '../../../helper/apiHelperFunctions'
import { parseJwt } from '../../../helper/genericFunction'
import { useDispatch, useSelector } from 'react-redux'
import { setSessionStorageItems, setLocalStorageItems } from '../LoginApiResponse'
import '../../../styles/stylesheets/home/SessionExpired.scss'
import SingleLogin from '../singleLogin/SingleLogin'
import LoginUsing2FA from '../2faLogin/LoginUsing2FA'
import Icons from '../../../assets/icons'

const SessionExpiredModal = (props) => {

    const { invalidToken, invalidatedSession } = props;

    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    let tokenData = parseJwt(sessionStorage.getItem("token"))
    const authStore = useSelector(state => state.authStore);

    const [prevLoginPayload, setPrevLoginPayload] = useState({});

    const [currentType, setCurrentType] = useState(invalidatedSession ? "invalidatedSession" : "normal");
    const [loginResponse, setLoginResponse] = useState({});

    const loginUser = async (data) => {
        await setSessionStorageItems(data);
        await setLocalStorageItems(data, dispatch);
    }

    useEffect(() => {
        if (authStore?.["refreshTokenData"]?.isSuccess) {
            setPrevLoginPayload({});
            loginUser(authStore?.["refreshTokenData"]?.data?.resource || {});
            props.closeModal();
        }
    }, [authStore?.["refreshTokenData"]?.isSuccess])

    useEffect(() => {
        if (authStore?.["refreshTokenData"]?.isError) {
            setLoginResponse(authStore?.["refreshTokenData"]?.message?.resource)
            if (authStore?.["refreshTokenData"]?.message?.code == "4826") {
                setCurrentType("singleLogin");

            }
            if (authStore?.["refreshTokenData"]?.message?.code == "4827") {
                setCurrentType("2faLogin");
            }
        }
    }, [authStore?.["refreshTokenData"]?.isError])

    const handleChange = (type, config) => {
        switch (type) {
            case "submit": {
                if (password?.trim() != "" && password?.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/)) {
                    let loginData = {
                        uType: tokenData?.uType || "",
                        username: btoa(tokenData?.prn),
                        password: btoa(password?.trim()),
                        hash: "true",
                        forceLogin: config?.forceLogin ? 1 : undefined,
                        mfaPasscode: config?.mfaPasscode ? config?.mfaPasscode : undefined
                    };
                    setPrevLoginPayload(prev => ({ ...prev, ...loginData }))
                    dispatchHandler(dispatch, "refreshTokenRequest", loginData);
                }
                break;
            }
        }
    }

    switch (currentType) {
        case "normal": {
            return (
                <>
                    <div className='backdrop'></div>
                    <div className='session-expired min-h-[275px]'>
                        <GenericFormHeaderComponent
                            label={{
                                contentPrimary: invalidToken ? "Your Session has invalidated" : "Your session has expired",
                                contentSecondry: "Please try to relogin by entering password. Don’t worry, we kept all of your filters and breakdowns in place."
                            }}

                            closeModal={props.closeModal}
                        />

                        <LoginPasswordInput
                            password={password}
                            setPassword={setPassword}
                            submit={() => handleChange("submit")}
                        />

                        <GenericFormFooterComponent
                            buttons={
                                [
                                    { type: "primary", label: "Relogin", disabled: () => (password?.trim() == "" || !password?.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/)), onClick: () => handleChange("submit") }
                                ]
                            }
                        />

                    </div>
                </>
            )
        }
        case "singleLogin": {
            return <div className='bg-white pt-[1rem]'>
                <SingleLogin
                    invalidToken={invalidToken || false}
                    details={{
                        // systemos : loginResponse?.resource?.["systemos"] || "Mac OS",
                        systemos: loginResponse?.["systemos"].replace(/\"/g, '') || "",
                        browser: loginResponse?.["browser"]?.split(';')[0].replace(/\"/g, '') || "",
                        sourceIp: loginResponse?.["sourceIp"] || '',
                        loginAgain: () => {
                            handleChange("submit", {
                                forceLogin: 1
                            })
                        }
                    }}
                />
            </div>
        }
        case "2faLogin": {
            return <div className='bg-white pt-[8px]'>
                <LoginUsing2FA
                    loginAgain={
                        (config) => {
                            handleChange("submit", {
                                forceLogin: prevLoginPayload?.forceLogin ? 1 : undefined,
                                mfaPasscode: config?.mfaPasscode ? config.mfaPasscode : undefined,
                            })
                        }
                    }
                />
            </div>
        }
        case "invalidatedSession" : {
            return <div className='text-center p-[2rem] flex flex-col gap-3 items-center justify-center text-[#0F172A]'>
                <Icons.SessionExpiredIcon/>
                <p className='mt-3 mb-0 text-xl font-semibold'>Your Session has Expired</p>
                <p className='mb-0 text-[13px] text-[#575D6A]'>Your session has expired. You cannot access this account because the session for your main account has also expired.</p>
                <p className='my-3 h-[1px] w-[75px] bg-[#D6D9E4]'></p>
                <div>
                    <p className='text-[14px] font-semibold'>Please close this tab</p>
                    <p className='mb-0 text-[11px]'>& reopen it from your active account.</p>
                </div>
            </div>
        }
    }
}

export default SessionExpiredModal