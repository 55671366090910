import React, { useEffect, useState } from "react";
import AuthStack from "./authComponents/AuthStack";
import UserOfflineHome from "./components/helperComponents/UserOfflineHome";
import {AppStack} from "./components/AppStack";
import MasterDbData from "./authComponents/MasterDbData";
import ApiManagement from "./authComponents/ApiManagement";
import { HashRouter } from "react-router-dom/cjs/react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import './styles/stylesheets/helper.scss'
import QrScan from "./authComponents/QrScan/QrScan";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import OtherStack from "./otherStacks/OtherStack";
import WarehouseJobStatusComponent from "./authComponents/WarehouseJobStatusComponent";
import { parseJwt } from "./helper/genericFunction";
import QzTraySecurityChecker from "./helper/components/QzTraySecurityChecker";
import BootConfigSetting from "./components/home/components/BootConfigSetting";


const ReloadContext = React.createContext();

function App() {
	const location = useLocation();
	const [isVendorLoginFromRetailer, setIsVendorLoginFromRetailer] = useState(false);
	const [bootConfig, setBootConfig] = useState({
		additionalConfig : {
			color : "#5d5fef",
			allowVendorRegistration : false
		}
	});

	let params = {};

	// Dev code
	// params = {
	// 	"token" : "eyJhbGciOiJIUzUxMiJ9.eyJtb2IiOiI5MzEzNDgzMTU0IiwiZWlkIjo4NzU1LCJsYXN0TmFtZSI6IlNoaXZuYW5pIiwibG9naW5TdHJpcE1lc3NhZ2UiOm51bGwsInNjaGVtYUVudE5hbWUiOiJTYW1iaGF2IFN0dWRpb3MiLCJlbWwiOiJzYW1iaGF2c2FyZWUxMjNAZ21haWwuY29tIiwicm9sZXMiOiIxMTU0IiwiWC1URU5BTlQtSUQiOiJkYmNmMGEzMzA2MDI0MjY5MDIzZTU5M2Q2YWNmYzllYjhjMmUxZGVmZDMwYWJlMGYyMDMxY2E0Nzk3OTEzMWU3IiwiQlVDS0VUIjoic2FtYmhhdi1zdXBwbHltaW50LWRldmVsb3AtY29tIiwiaXNzIjoiU3VwcGx5TWludCIsIm1vYmlsZVZlcmlmaWVkIjoxLCJvaWQiOjg5NTYsInVzZXJBc0FkbWluIjpmYWxzZSwiZ3N0aW4iOm51bGwsIm9yZ0lkIjoiODk1NiIsIm9jb2RlIjoiODgwNWQ0MTMtMzRkNy00ZjIxLTgzZjktMGZiZmY0MzIwNDcyIiwidVR5cGUiOiJWRU5ET1IiLCJpcGEiOiJJUEEiLCJmdW5jdGlvblR5cGUiOiJERUZBVUxUX0ZVTkNUSU9OIiwiZXhwIjoxNzIyMDgzNzIxLCJqdGkiOjEzMTAzLCJvcmdOYW1lIjoiU0FNQkhBViBTQVJFRSIsImNvcmVPcmdJRCI6Ijg5NTYiLCJzbENvZGUiOiIxIiwiZW50RGV0YWlscyI6W3siZWlkIjo4NzMxLCJvaWQiOjg5MzIsImVjb2RlIjoic2FtYmhhdiIsImVuYW1lIjoiU0FNQkhBViBTQVJFRS1TYW1iaGF2IFN0dWRpb3MiLCJ1Y29kZSI6IjEiLCJkZWZhdWx0IjoxLCJzdWJVc2VyVHlwZSI6IlZlbmRvciIsImlzSG9sZCI6MCwiaG9sZExhYmVsIjpudWxsLCJob2xkTWVzc2FnZSI6bnVsbCwiYWN0aXZlIjoxfV0sInBybiI6InNhbWJoYXZzYXJlZXMiLCJlY29kZSI6Ijg4MDVkNDEzLTM0ZDctNGYyMS04M2Y5LTBmYmZmNDMyMDQ3MiIsIk9SR19ERVRBSUxTIjpbXSwiYXVkIjoiRElMSVBLVU1BUiBTSElWTkFOSSAgU2hpdm5hbmkiLCJzY2hlbWFFbnRJRCI6Ijg3MzEiLCJlbWFpbFZlcmlmaWVkIjoxLCJmaXJzdE5hbWUiOiJESUxJUEtVTUFSIFNISVZOQU5JICIsImVuYW1lIjoiU0FNQkhBViBTQVJFRSIsInN1YlVzZXJUeXBlIjoiViIsIndlYklkIjpudWxsLCJpcF9wcmVmaXgiOlsiSVBPQUEiXSwidGVuYW50T3JnSUQiOiI4OTU2IiwiaXNNZXJnZUVsaWdpYmxlIjowLCJzY2hlbWFPcmdJRCI6Ijg5MzIiLCJFTlRfVFlQRSI6IlZFTkRPUiIsImxvZ2luU291cmNlIjoiU0VMRiIsIm9yZ19sb2dvIjoiaHR0cHM6Ly9hc3NldHMtc3VwcGx5bWludC1jb20uczMuYXAtc291dGgtMS5hbWF6b25hd3MuY29tL29yZ19hc3NldHMvaW1nX3BsYWNlaG9sZGVyL3Byb2ZpbGVQbGFjZWhvbGRlci5zdmcifQ.7o1CvHhQOk9oFrQiunsIcXxnItqbLaxTqubII9nDm21imgUtZuQh6DR7JNIp4rxE7-3P97W1LSXszw4APqzDEA"
	// }
	// DevCode

	if (location?.hash?.split('?')?.[1]?.substring(0, 19)?.includes("eyJhbGciOiJIUzUxMiJ")) {
		params = {
			"token": location?.hash?.split('?')?.[1],
		}
		if (location?.hash?.split('?')?.[0]?.includes("hitLineReport") || location?.hash?.split('?')?.[0]?.includes("footfallReport")) {
			sessionStorage.setItem("redirectNewUrl", location?.hash?.split('?')?.[0])
		}
	}

	if (window.location.hash.includes("accessVendorPortalToken")) {
		params = {
			"token": window.location.hash?.split("?")?.[1]?.split?.("=")?.[1]
		};
	}

	const [isUserOnline, setIsUserOnline] = useState(navigator.onLine);
	const [currentDocumentState, setCurrentDocumentState] = useState("");
	// state : to store the appStack document state
	const [appStackDocState, setAppStackDocState] = useState("");

	useEffect(() => {
		if (window.location.hash.includes("accessVendorPortalToken")) {
			setIsVendorLoginFromRetailer(true);
		}
	}, [])

	useEffect(() => {
		console.log("Daassdassd", location.pathname);
		// To handle the user is offline or online
		window.addEventListener("online", handleOnline);
		window.addEventListener("offline", handleOnline);

		handleDocumentStatusChange();
	}, [params, params.token]);

	const handleDocumentStatusChange = () => {
		let status = "unauthorised";

		if (window.location.hash?.includes("accessVendorPortalToken")) {
			status = "rejected"
		} else if (!sessionStorage?.getItem("token")) {
			// Check if the token is set inside session storage
			if (!params.token) {
				status = "rejected"; // if token is not present is params then the user cannot access the application.
			}
		} else {
			status = "authorised"; // else the user is authorised.
		}
		setCurrentDocumentState(status);
	}

	useEffect(() => {
		if (sessionStorage.getItem("appStackDocState")) {
			setAppStackDocState(sessionStorage.getItem("appStackDocState"));
		}
	}, [])

	useEffect(() => {
		sessionStorage.setItem("appStackDocState", appStackDocState)
	}, [appStackDocState])

	function handleOnline() {
		setTimeout(() => {
			setIsUserOnline(navigator.onLine)
		}, navigator.onLine ? 1900 : 0)
	}

	useEffect(() => {
		if (currentDocumentState == "authorised") {
			let uType = parseJwt(sessionStorage.getItem("token"))?.uType;

			if (["VENDOR", "CUSTOMER"]?.includes(uType)) {
				const script = document.createElement("script");
				script.src = '//in.fw-cdn.com/31764711/906749.js';
				script.chat = 'true';
				console.log("Append child", script, document.body);

				document.body?.appendChild(script);
			}
		}
	}, [currentDocumentState])

	const renderApp = () => {
		console.log("Currrent Doc State", currentDocumentState)
		let Component = null;
		const props = {};
		// eslint-disable-next-line default-case
		switch (currentDocumentState) {
			case "unauthorised":
			case "rejected": {
				// window.location.href = "/"
				Component = AuthStack;

				props.token = params?.["token"];
				props.setCurrentDocumentState = setCurrentDocumentState;
				props.setAppStackDocState = setAppStackDocState;
				props.currentDocumentState = currentDocumentState
				props.isVendorLoginFromRetailer = isVendorLoginFromRetailer
				break;
			}
			case "otherStack": {
				Component = OtherStack;
				break;
			}
			case "authorised": {
				props.token = params?.["token"];
				props.setCurrentDocumentState = setCurrentDocumentState;
				props.setAppStackDocState = setAppStackDocState;
				switch (appStackDocState) {
					case "otherStack": {
						Component = OtherStack;
						break;
					}
					default: {
						Component = AppStack
					}
				}
				break;
			}
		}
		if (Component) {
			return (
				<React.Fragment>
					<Component {...props} />
					{(sessionStorage?.getItem("HIDE_OFFLINE_STRIP") != "TRUE") && (!isUserOnline && <UserOfflineHome />)}
				</React.Fragment>
			);
		}
	};

	return (
		<div id="app">
			<ReloadContext.Provider value={{ 
				handleDocumentStatusChange : () => {
					setCurrentDocumentState("unauthorised")
				},
				setBootConfig : setBootConfig,
				bootConfig : bootConfig,
			}}>
				<HashRouter>
						
					<BootConfigSetting/>

					<QzTraySecurityChecker />

					{/* MasterDbData -> Component to call and set Headers, filters and dropdowns data */}
					<MasterDbData />

					{/* Warehouse job run status component to show the status of the running job */}
					<WarehouseJobStatusComponent />

					{/* Loading, Error Message, Success Messages */}
					<ApiManagement />

					{currentDocumentState ? renderApp() : null}

					{/* Render the qr scan for product catalog */}
					<Route exact path="/qrscan" render={() => <QrScan location={location} />} />
				</HashRouter>
			</ReloadContext.Provider>
		</div>
	);
}
export {App, ReloadContext};