import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { getHomeLink, parseJwt } from "../helper/genericFunction";
import { useHistory, useLocation } from "react-router-dom";
import GenericCustomButton from "../genericComponents/GenericCustomButton";
import IconsPath from "../assets/icons";
// import DocumentsDropdown from "./oldGenericComponents/DocumentsDropdown";
import DocumentsDropdown from "./oldGenericComponents/tableHelperComponents/DocumentsDropdown";
import {getNumberOfStrips} from '../initialPhaseOperations/InitialAnnouncementModal';
import LanguageTranslator from "../locals/LanguageTranslator";

const BreadCrumbs = (props) => {
    const { actionBarConfig } = props;

    const history = useHistory();
    const location = useLocation();

    const [dashboardModules, setDashboardModules] = useState([]);
    const [parentModule, setParentModule] = useState({});
    const [currentPageName, setCurrentPageName] = useState("");
    const [stripHeight, setStripHeight] = useState(getNumberOfStrips() || 0);
    const uType = parseJwt(sessionStorage.getItem('token')).uType?.toUpperCase() || "ENT";

    useEffect(() => {
        setInterval(() => {
            setStripHeight(getNumberOfStrips())
        }, 400)
    }, [])

    useEffect(() => {
        let updatedParentModule = {};
        let modules = JSON.parse(sessionStorage.getItem("modules"));
        let availableDashboardModules = JSON.parse(
            sessionStorage.getItem("dashboardModules")
        );
        if (availableDashboardModules) {
            setDashboardModules(availableDashboardModules);
        }
        if (modules && modules.length) {
            for (let i = 0; i < modules.length; i++) {
                updatedParentModule = getParentModule(modules[i]);
                if (Object.keys(updatedParentModule).length) {
                    break;
                }
            }
        }
        setParentModule(updatedParentModule);
    }, []);

    const getParentModule = (module, fallback) => {
        let subModules = module.subModules;
        if (subModules) {
            const HASH = `#${location.pathname}`;
            for (let i = 0; i < subModules.length; i++) {
                if (subModules[i].pageUrl === HASH) {
                    setCurrentPageName(subModules[i].name);
                    document.title = subModules[i].name;
                    if (module.isPage == 1) return fallback;
                    return module;
                } else {
                    let temp = getParentModule(subModules[i], module);
                    if (Object.keys(temp).length) return temp;
                }
            }
        }
        return {};
    };

    const renderCurrentPage = (data, uniqKey) => {
        if (data?.subModules !== null && data?.isPage == 0) {
            return (
                <li className="breadcrump-sub-menu" key={uniqKey}>
                    {data !== undefined && (
                        <span className="bsm-head">
                            <LanguageTranslator tag="span" className="sm-head-inner">{data.name}</LanguageTranslator>
                            <IconsPath.BCRightArrow />
                            <ul className="breadcrump-sub-menu2">
                                {data?.subModules?.map((node, key) => (
                                    <LanguageTranslator tag="li" className="bsm2-item" key={key}>
                                        {renderCurrentPage(node, key)}
                                    </LanguageTranslator>
                                ))}
                            </ul>
                        </span>
                    )}
                </li>
            );
        } else
            return (
                <LanguageTranslator 
                    tag="span"
                    className="bm-inner-item"
                    key={uniqKey}
                    onClick={() => openPage(data)}>
                    {data.name}
                </LanguageTranslator>
            );
    };

    const openPage = (root) => {
        history.push(root.pageUrl?.replace("#", ""));
    };

    const handleGoBack = () => {
        history.goBack();
    };

    return (
        <React.Fragment>
            <div className={`main-bread-crumps top-${stripHeight + 45}`}>
                {<ul className="mbc-left">
                    {!props.breadCrumbsProps?.hideRoute && <>
                        <li className="mbcl-list">
                            <GenericCustomButton
                                className="mbcl-back-btn"
                                onClick={handleGoBack}
                                disabled={false}
                                loading={false}
                                leftIcon={<IconsPath.BackArrowIcon />}
                                label="Back"
                            />
                        </li>
                        <li className="mbcl-list">
                            <LanguageTranslator tag="Link" to={getHomeLink(dashboardModules)}>Home</LanguageTranslator>
                        </li>
                        <li className="mbcl-list mbcll-mid-sec">
                            {window.location?.hash?.split("/")?.[1] !== "home" && (
                                <span className="mbcll-left">
                                    <span className="mbcll-icon">
                                        {/* <svg
                                            width="6"
                                            height="10"
                                            viewBox="0 0 6 10"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0.357177 0.419677C0.609454 0.167399 1.01848 0.167399 1.27075 0.419677L5.14673 4.29565C5.39901 4.54793 5.39901 4.95695 5.14673 5.20923L1.27075 9.08521C1.01848 9.33748 0.609454 9.33748 0.357177 9.08521C0.104899 8.83293 0.104899 8.42391 0.357177 8.17163L3.77637 4.75244L0.357177 1.33325C0.104899 1.08098 0.104899 0.671954 0.357177 0.419677Z"
                                                fill="#67768E"
                                            />
                                        </svg> */}
                                    </span>
                                    {/* {console.log(parentModule?.name)} */}
                                    <LanguageTranslator>{parentModule?.name ?? ''}</LanguageTranslator>
                                    <IconsPath.DownArrowFilled className="mbclll-down-arrow" />
                                </span>
                            )}
                            {parentModule?.subModules && (
                                <ul className="mbcllms-breadcrump-menu">
                                    {parentModule?.subModules?.map((_, key) =>
                                        renderCurrentPage(_, key)
                                    )}
                                </ul>
                            )}
                        </li>
                        <li className="mbcl-list mbcl-active-list">
                            <span className="mbcll-left">
                                <span className="mbcll-icon">
                                    <svg
                                        width="6"
                                        height="10"
                                        viewBox="0 0 6 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0.357177 0.419677C0.609454 0.167399 1.01848 0.167399 1.27075 0.419677L5.14673 4.29565C5.39901 4.54793 5.39901 4.95695 5.14673 5.20923L1.27075 9.08521C1.01848 9.33748 0.609454 9.33748 0.357177 9.08521C0.104899 8.83293 0.104899 8.42391 0.357177 8.17163L3.77637 4.75244L0.357177 1.33325C0.104899 1.08098 0.104899 0.671954 0.357177 0.419677Z"
                                            fill="#67768E"
                                        />
                                    </svg>
                                </span>
                                <LanguageTranslator>{currentPageName}</LanguageTranslator>
                            </span>
                        </li>
                    </>}
                </ul>}
                <div className="mbc-right">
                    {props?.time !== undefined &&
                        props?.time !== "" &&
                        props?.time != null && (
                            <span className="breadcum-text">
                                <LanguageTranslator>Last Save :{" "}</LanguageTranslator>
                                <span className="breadcum-text-bold">
                                    {moment(props?.time).format("hh:mm A")} /{" "}
                                    {moment(props?.time).format("DD-MMMM-YYYY")}{" "}
                                </span>
                            </span>
                        )}
                    {actionBarConfig && actionBarConfig.createNew && (
                        <GenericCustomButton
                            sendEventAnalyticsToGoogle = {true}
                            className="generic-approve-btn"
                            onClick={
                                typeof actionBarConfig?.createNew?.onClick === "function"
                                    ? actionBarConfig.createNew?.onClick()
                                    : null
                            }
                            disabled={actionBarConfig?.createNew?.disabled}
                            loading={false}
                            leftIcon={<IconsPath.CreatePlusIcon />}
                            label="Create New"
                        />
                    )}
                    {props?.getTableApiData && props?.getTableApiData?.mvUpdateDate && (
                        <span className="header-last-update"><LanguageTranslator>Last Updated</LanguageTranslator>{` : ${moment(
                            props?.getTableApiData?.mvUpdateDate || ""
                        ).format("DD-MMM-YYYY h:mm:ss a")}`}</span>
                    )}

                    {uType == "ENT" && props?.getTableApiData && props?.getTableApiData?.mvName && (
                        <GenericCustomButton
                            sendEventAnalyticsToGoogle = {true}
                            label="Refresh Data"
                            loading={props?.generalStore?.getRefreshReport?.isLoading}
                            className="generic-approve-btn wid100"
                            onClick={props?.refreshapidata}
                        />
                    )}
                    {actionBarConfig &&
                        actionBarConfig.rhsExtras &&
                        actionBarConfig.rhsExtras(props.selectedItems)}

                    {(Object.keys(props.downloadDocumentsConfigurationObject || {})?.length > 0) && (
                        <div className="gvpd-download-drop">
                            <GenericCustomButton
                                className={
                                    Object.keys(props?.selectedItems).length
                                        ? `gen-action-btn ${props?.documentsDropdown
                                            ? "gen-action-btn-focus"
                                            : "gen-action-btn"
                                        }`
                                        : "opacity04 gen-action-btn"
                                }
                                loading={props?.generalStore?.getDocumentDropdownData?.isLoading}
                                onClick={props.OpenDocumentsDropdown}
                                disabled={
                                    !Object.keys(props?.selectedItems).length ||
                                    actionBarConfig?.downloadDocuments?.disabled
                                }
                                leftIcon={
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M2.70825 11.0703C3.13487 11.0703 3.48071 11.4162 3.48071 11.8428V14.9326C3.48071 15.1375 3.5621 15.334 3.70696 15.4788C3.85183 15.6237 4.0483 15.7051 4.25317 15.7051H15.0676C15.2725 15.7051 15.469 15.6237 15.6138 15.4788C15.7587 15.334 15.8401 15.1375 15.8401 14.9326V11.8428C15.8401 11.4162 16.1859 11.0703 16.6125 11.0703C17.0392 11.0703 17.385 11.4162 17.385 11.8428V14.9326C17.385 15.5472 17.1409 16.1367 16.7063 16.5713C16.2717 17.0058 15.6822 17.25 15.0676 17.25H4.25317C3.63857 17.25 3.04913 17.0058 2.61454 16.5713C2.17994 16.1367 1.93579 15.5472 1.93579 14.9326V11.8428C1.93579 11.4162 2.28163 11.0703 2.70825 11.0703Z" fill={(Object.keys(props.selectedItems).length && !(actionBarConfig?.downloadDocuments?.disabledWithValues && Object.values(props?.selectedItems).some(item => item[actionBarConfig?.downloadDocuments?.keyForDisable] !== actionBarConfig?.downloadDocuments?.valueForDisable))) ? "#23235F" : "#d8d3d3"} />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.25188 7.43719C5.55355 7.13552 6.04264 7.13552 6.34431 7.43719L9.6604 10.7533L12.9765 7.43719C13.2782 7.13552 13.7673 7.13552 14.0689 7.43719C14.3706 7.73885 14.3706 8.22795 14.0689 8.52961L10.2066 12.3919C9.90495 12.6936 9.41585 12.6936 9.11419 12.3919L5.25188 8.52961C4.95022 8.22795 4.95022 7.73885 5.25188 7.43719Z" fill={(Object.keys(props.selectedItems).length && !(actionBarConfig?.downloadDocuments?.disabledWithValues && Object.values(props?.selectedItems).some(item => item[actionBarConfig?.downloadDocuments?.keyForDisable] !== actionBarConfig?.downloadDocuments?.valueForDisable))) ? "#23235F" : "#d8d3d3"} />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.6604 1.80469C10.087 1.80469 10.4329 2.15053 10.4329 2.57715V11.8467C10.4329 12.2733 10.087 12.6191 9.6604 12.6191C9.23378 12.6191 8.88794 12.2733 8.88794 11.8467V2.57715C8.88794 2.15053 9.23378 1.80469 9.6604 1.80469Z" fill={(Object.keys(props.selectedItems).length && !(actionBarConfig?.downloadDocuments?.disabledWithValues && Object.values(props?.selectedItems).some(item => item[actionBarConfig?.downloadDocuments?.keyForDisable] !== actionBarConfig?.downloadDocuments?.valueForDisable))) ? "#23235F" : "#d8d3d3"} />
                                    </svg>
                                }
                                label={
                                    Object.keys(props?.selectedItems || {}).length > 0 && (
                                        <LanguageTranslator tag="span" className="generic-tooltip zi999">
                                            Download Documents
                                        </LanguageTranslator>
                                    )
                                }
                            />
                            {props?.documentsDropdown && (
                                <div className="backdrop-transparent zIndex9" onClick={props?.OpenDocumentsDropdown}></div>
                            )}
                            {props?.documentsDropdown && (
                                <DocumentsDropdown
                                    OpenDocumentsDropdown={props.OpenDocumentsDropdown}
                                    downloadDocumentsConfigurationObject={props.downloadDocumentsConfigurationObject || {}}
                                    checkItems={Object.values(props.selectedItems)}
                                    downloadDocumentsRequest={props.downloadDocumentsRequest}
                                    downloadDocuments={actionBarConfig?.downloadDocuments || {}}
                                    {...props}
                                />
                            )}
                        </div>
                    )}
                    {/* 
                    {actionBarConfig && actionBarConfig.downloadDocuments &&
                        <div className="gvpd-download-drop">
                            <button type="button" className={Object.keys(props?.selectedItems).length ? `gen-action-btn ${props?.documentsDropdown ? "gen-action-btn-focus" : ""}` : "gen-action-btn opacity04"} onClick={props.OpenDocumentsDropdown} disabled={!Object.keys(props?.selectedItems).length || actionBarConfig?.downloadDocuments?.disabled || actionBarConfig?.downloadDocuments?.disabledWithValues && Object.values(props?.selectedItems).some(item => item[actionBarConfig?.downloadDocuments?.keyForDisable] !== actionBarConfig?.downloadDocuments?.valueForDisable)}>
                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.70825 11.0703C3.13487 11.0703 3.48071 11.4162 3.48071 11.8428V14.9326C3.48071 15.1375 3.5621 15.334 3.70696 15.4788C3.85183 15.6237 4.0483 15.7051 4.25317 15.7051H15.0676C15.2725 15.7051 15.469 15.6237 15.6138 15.4788C15.7587 15.334 15.8401 15.1375 15.8401 14.9326V11.8428C15.8401 11.4162 16.1859 11.0703 16.6125 11.0703C17.0392 11.0703 17.385 11.4162 17.385 11.8428V14.9326C17.385 15.5472 17.1409 16.1367 16.7063 16.5713C16.2717 17.0058 15.6822 17.25 15.0676 17.25H4.25317C3.63857 17.25 3.04913 17.0058 2.61454 16.5713C2.17994 16.1367 1.93579 15.5472 1.93579 14.9326V11.8428C1.93579 11.4162 2.28163 11.0703 2.70825 11.0703Z" fill={(Object.keys(props.selectedItems).length && !(actionBarConfig?.downloadDocuments?.disabledWithValues && Object.values(props?.selectedItems).some(item => item[actionBarConfig?.downloadDocuments?.keyForDisable] !== actionBarConfig?.downloadDocuments?.valueForDisable))) ? "#23235F" : "#d8d3d3"} />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.25188 7.43719C5.55355 7.13552 6.04264 7.13552 6.34431 7.43719L9.6604 10.7533L12.9765 7.43719C13.2782 7.13552 13.7673 7.13552 14.0689 7.43719C14.3706 7.73885 14.3706 8.22795 14.0689 8.52961L10.2066 12.3919C9.90495 12.6936 9.41585 12.6936 9.11419 12.3919L5.25188 8.52961C4.95022 8.22795 4.95022 7.73885 5.25188 7.43719Z" fill={(Object.keys(props.selectedItems).length && !(actionBarConfig?.downloadDocuments?.disabledWithValues && Object.values(props?.selectedItems).some(item => item[actionBarConfig?.downloadDocuments?.keyForDisable] !== actionBarConfig?.downloadDocuments?.valueForDisable))) ? "#23235F" : "#d8d3d3"} />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M9.6604 1.80469C10.087 1.80469 10.4329 2.15053 10.4329 2.57715V11.8467C10.4329 12.2733 10.087 12.6191 9.6604 12.6191C9.23378 12.6191 8.88794 12.2733 8.88794 11.8467V2.57715C8.88794 2.15053 9.23378 1.80469 9.6604 1.80469Z" fill={(Object.keys(props.selectedItems).length && !(actionBarConfig?.downloadDocuments?.disabledWithValues && Object.values(props?.selectedItems).some(item => item[actionBarConfig?.downloadDocuments?.keyForDisable] !== actionBarConfig?.downloadDocuments?.valueForDisable))) ? "#23235F" : "#d8d3d3"} />
                                </svg>
                                {Object.keys(props?.selectedItems).length > 0 && <span className="generic-tooltip zi999">Download Documents</span>}
                            </button>
                            {props?.documentsDropdown && <div className='backdrop-transparent zIndex9' onClick={props?.OpenDocumentsDropdown}></div>}
                            {props?.documentsDropdown &&
                                <DocumentsDropdown
                                    OpenDocumentsDropdown={props.OpenDocumentsDropdown}
                                    checkItems={Object.values(props.selectedItems)}
                                    downloadDocumentsRequest={props.downloadDocumentsRequest}
                                    drop={actionBarConfig.downloadDocuments.drop}
                                    {...props}
                                />}
                        </div>} */}


                    {actionBarConfig && actionBarConfig.emailReport && (
                        <GenericCustomButton
                            className="gen-action-btn"
                            onClick={props.OpenEmailModal}
                            disabled={actionBarConfig.emailReport?.disabled}
                            loading={false}
                            leftIcon={<IconsPath.EmailReport />}
                            label={
                                <LanguageTranslator tag="span" className="generic-tooltip zi999">Email Report</LanguageTranslator>
                            }
                        />
                    )}

                    {actionBarConfig && actionBarConfig?.downloadReport && (
                        <GenericCustomButton
                            className="gen-action-btn"
                            onClick={props.ToggleDownloadReportModal}
                            loading={false}
                            disabled={
                                props.generalStore?.getHeadersData?.isLoading ||
                                props.generalStore.downloadReport?.isLoading
                            }
                            leftIcon={<IconsPath.ExportToExcel />}
                            label={
                                <LanguageTranslator tag="span" className="generic-tooltip zi999">Export to Excel</LanguageTranslator>
                            }
                        />
                    )}

                    {actionBarConfig &&
                        (actionBarConfig?.moreMenu || actionBarConfig?.documentLog) && (
                            <div className="gvpd-three-dot-btn">
                                <span className="gvpdtd-btn">
                                    <svg
                                        className="threeDot-svg"
                                        onClick={props?.OpenMoreMenu}
                                        width="32"
                                        height="32"
                                        viewBox="0 0 36 35"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle
                                            cx="24.875"
                                            cy="17.875"
                                            r="1.875"
                                            transform="rotate(90 24.875 17.875)"
                                            fill="#23235F"></circle>
                                        <circle
                                            cx="18.125"
                                            cy="17.875"
                                            r="1.875"
                                            transform="rotate(90 18.125 17.875)"
                                            fill="#23235F"></circle>
                                        <circle
                                            cx="11.375"
                                            cy="17.875"
                                            r="1.875"
                                            transform="rotate(90 11.375 17.875)"
                                            fill="#23235F"></circle>
                                    </svg>
                                    <LanguageTranslator tag="span" className="generic-tooltip">More</LanguageTranslator>
                                </span>
                                {props?.moreMenuDropdown && (
                                    <div className="gvpdtd-menu">
                                        <ul className="gvpdtdm-inner">
                                            {actionBarConfig.moreMenu &&
                                                Object.keys(
                                                    actionBarConfig.moreMenu.options(props?.selectedItems)
                                                ).map((key) => {
                                                    let item = actionBarConfig.moreMenu.options(
                                                        props?.selectedItems
                                                    )[key];
                                                    if (item) {
                                                        return (
                                                            <li key={key} className={key}>
                                                                <GenericCustomButton
                                                                    sendEventAnalyticsToGoogle = {true}
                                                                    className={`gvpdtdm-${item.type} ${item.disabled && "opacity04"
                                                                        }`}
                                                                    onClick={
                                                                        item?.disabled
                                                                            ? null
                                                                            : item?.confirm
                                                                                ? props?.ConfirmAction(key)
                                                                                : item?.excelExport
                                                                                    ? item?.onClick(props?.tableDataPayload)
                                                                                    : item?.onClick
                                                                    }
                                                                    disabled={false}
                                                                    loading={false}
                                                                    label={item.label}
                                                                />
                                                            </li>
                                                        );
                                                    }
                                                })}
                                            {actionBarConfig.documentLog && (
                                                <li>
                                                    <GenericCustomButton
                                                        sendEventAnalyticsToGoogle = {true}
                                                        className={`gvpdtdm-inner}`}
                                                        onClick={() =>
                                                            props.handleTrueFalse(
                                                                "openDocumentLog",
                                                                props.selectedItems
                                                            )
                                                        }
                                                        disabled={
                                                            Object.keys(props.selectedItems).length !== 1
                                                        }
                                                        loading={false}
                                                        label="Logs"
                                                    />
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default BreadCrumbs;
