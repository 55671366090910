import React, { useEffect, useState } from 'react'
import GenericInput from '../../genericComponents/input/GenericInputComponent'
import Icons from '../../assets/icons';

const LoginPasswordInput = (props) => {
    const {setPassword, submit,password} = props;

    const [showPasswordValue, setShowPasswordValue] = useState(false);

    useEffect(()=>{
        document.getElementById("passwordGenericInput")?.focus();
    },[])
    
    return (
        <GenericInput
            id = "passwordGenericInput"
            key="password"
            className= {`gibp-password-padding ${props.className}`}
            label = {props?.label || "Password"}
            type={showPasswordValue ? "text" : "password"}
            iconRight={<button className='psswrd-eye-icon' onClick={() => setShowPasswordValue(!showPasswordValue)}>
                {!showPasswordValue ? <Icons.PasswordEyeIcon /> : <Icons.PasswordEyeDisabledIcon />}
            </button>}
            value={password || ""}
            iconLeft = {<span className='input-icons'><Icons.PasswordLockIcon /></span>}
            placeholder = {props.placeholder || ""}
            onChange={(e) => setPassword(e?.target?.value || "")}
            onKeyDown={(e) => e.key == "Enter" && (submit())}
        />
    )
}

export default LoginPasswordInput